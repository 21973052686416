import {Component, OnInit} from '@angular/core';
import {Document} from "@app/entity/Document";
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";

@Component({
  selector: 'app-documentation-page',
  templateUrl: './documentation-page.component.html',
  styleUrls: ['./documentation-page.component.css']
})
export class DocumentationPageComponent implements OnInit {
  private documentationService: DocumentationService;

  commonDocuments: Document[];
  deviceDocumentations: DeviceDocumentation[];

  expandedDeviceIds = new Set<number>();

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;

    this.fillCommonDocuments();
  }

  toggleDocumentation(deviceId: number): void {
    if (this.expandedDeviceIds.has(deviceId)) {
      this.expandedDeviceIds.delete(deviceId);
    } else {
      this.expandedDeviceIds.add(deviceId);
    }
  }

  isDocumentationVisible(deviceId: number): boolean {
    return this.expandedDeviceIds.has(deviceId);
  }

  ngOnInit() {
    this.deviceDocumentations = this.documentationService.getDocumentations();
  }

  private fillCommonDocuments() {
    this.commonDocuments = [
      {documentName: 'Декларация о соответствии требованиям (ТРТС 020/2011)',
        documentPath: this.documentationService.documentFolder + 'Declaration_Delta.pdf',
        iconPath: this.documentationService.iconFolder + 'declaration.png'}
    ];
  }
}
