import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FormRequest} from "@app/entity/FormRequest";

@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  private botToken = '8050503282:AAG4CsCT5GOuYQ7TaAMCFaAwDofsHzWc_MY';
  private chatId = '-1002275625182';

  private http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  sendMessage(formRequest: FormRequest): Observable<any> {
    const text = `
    📝 Новая заявка!
👤 ${formRequest.profession} ${formRequest.name}
⚙ ${formRequest.subjectOfInterest}
☎️ ${formRequest.phone}
    `;

    const url = `https://api.telegram.org/bot${this.botToken}/sendMessage`;
    const body = {
      chat_id: this.chatId,
      text,
    };

    return this.http.post(url, body);
  }
}
