import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-box',
  templateUrl: './group-box.component.html',
  styleUrls: ['./group-box.component.css']
})
export class GroupBoxComponent {
  @Input() itemId: bigint;
  @Input() title: string = 'Заголовок';
  @Input() textColor: string = '#000';
  @Input() borderColor: string = '#ccc';
  @Input() borderRadius: string = '5px';
  @Input() backgroundOfTitle: string = '#ffffff';
  @Input() onTitleClick: (bigint) => void;

  getCursor(): string {
    if (this.onTitleClick) {
      return 'pointer';
    }
    return 'default';
  }
}
