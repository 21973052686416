import { Component } from '@angular/core';
import {Instruction} from "@app/entity/Instruction";
import {Equipment} from "@app/entity/Equipment";
import {SolutionTab} from "@app/entity/SolutionTab";

@Component({
  selector: 'app-heating',
  templateUrl: './heating.component.html',
  styleUrls: ['../../own-block-styles.css', './heating.component.css']
})
export class HeatingComponent {
  private imageFolder = 'assets/images/';
  private deviceFolder = this.imageFolder + 'devices/';

  tabs: SolutionTab[] = [
    {tabName: 'стандартный (ИТП)', title: 'ИНДИВИДУАЛЬНЫЙ ТЕПЛОВОЙ ПУНКТ', description: 'стандартный вариант',
      parameterSets: [
        {name: 'отопление', steps: [
            'потреблённая тепловая энергия',
            'объём и текущий расход теплоносителя',
            'температура подачи и обратки',
            'время работы теплосчётчика'
          ]
        },
        {name: 'горячее водоснабжение', steps: [
            'потреблённая тепловая энергия',
            'объём воды на подаче и обратке',
            'текущий расход воды на подаче и обратке',
            'температура подачи и обратки',
            'время работы теплосчётчика'
          ]
        },
        {name: 'ТЭЦ', steps: [
            'температура подачи и обратки'
          ]
        },
        {name: 'теплоузел', steps: [
            'температура наружного воздуха'
          ]
        }
      ],
    equipments: [
      {name: 'контроллер «Дельта\u20115» с\u00A0ПО', imagePath: this.deviceFolder + 'controller.png', amountStr: '1 шт'},
      {name: 'GSM\u2011модем IRZ ATM31\u2011485', imagePath: this.imageFolder + 'modem-equipment.png', amountStr: '1 шт'},
      {name: 'блок питания с\u00A0аккумулятором', imagePath: this.imageFolder + 'block-power.png', amountStr: '1 шт'}
      ]
    },

    {tabName: 'расширенный (ЦТП)', title: 'ЦЕНТРАЛЬНЫЙ ТЕПЛОВОЙ ПУНКТ', description: 'расширенный вариант',
      parameterSets: [
        {name: 'отопление', steps: [
            'потреблённая тепловая энергия',
            'объём и текущий расход теплоносителя',
            'температура подачи и обратки',
            'время работы теплосчётчика',
            'давление в трубопроводах подачи и обратки',
            'температура воды в обратных трубопроводах дополнительных ветвей',
            'объём воды, поданной на подпитку'
          ]
        },
        {name: 'горячее водоснабжение', steps: [
            'потреблённая тепловая энергия',
            'объём воды на подаче и обратке',
            'текущий расход воды на подаче и обратке',
            'температура подачи и обратки',
            'время работы теплосчётчика',
            'давление в трубопроводах подачи и обратки',
            'температура воды в обратных трубопроводах дополнительных ветвей',
            'объём поданной холодной воды'
          ]
        },
        {name: 'ТЭЦ', steps: [
            'температура подачи и обратки',
            'давление в трубопроводах подачи и обратки'
          ]
        },
        {name: 'контуры теплообменников', steps: [
            'давление в трубопроводах'
          ]
        },
        {name: 'теплоузел', steps: [
            'температура наружного воздуха',
            'температура и влажность в помещении',
            'потреблённая электрическая энергия',
            'состояние входной двери (открыта или закрыта)',
            'наличие движения людей в помещении',
            'включение освещения в помещении',
            'наличие затопления (протечек)',
            'исчезновение напряжения питания ~220 В'
          ]
        },
        {name: 'оборудование', steps: [
            'состояние циркуляционных насосов (вкл/выкл, потребляемые токи)'
          ]
        }
      ],
      equipments: [
        {name: 'контроллер «Дельта\u20115» с\u00A0ПО в\u00A0коробке\u00A0IP55', imagePath: this.deviceFolder + 'controller.png', amountStr: '1 шт'},
        {name: 'GSM\u2011модем IRZ ATM31\u2011485 в\u00A0коробке\u00A0IP55', imagePath: this.imageFolder + 'modem-equipment.png', amountStr: '1 шт'},
        {name: 'блок питания с\u00A0аккумулятором в\u00A0коробке\u00A0IP55', imagePath: this.imageFolder + 'block-power.png', amountStr: '1 шт'},
        {name: 'манометр цифровой МЦ\u20111,6', imagePath: this.deviceFolder + 'manometer.png', amountStr: '2-6 шт'},
        {name: 'счётчик импульсов автономный\u00A0СИА\u201124М', imagePath: this.deviceFolder + 'sia.png', amountStr: '1-3 шт'},
        {name: 'извещатель магнитоконтактный', imagePath: this.imageFolder + 'contact-detector.png', amountStr: '1-2 шт'},
        {name: 'датчик тока аналоговый', imagePath: this.imageFolder + 'current-detector.png', amountStr: '2-4 шт'},
        {name: 'датчик температуры\u00A0ДТЦ\u201124М', imagePath: this.deviceFolder + 'dtc.png', amountStr: '2-4 шт'},
        {name: 'датчик влажности и\u00A0температуры', imagePath: this.imageFolder + 'dvtp-equipment.png', amountStr: '2-4 шт'},
        {name: 'датчик затопления\u00A0ДЗ\u201124', imagePath: this.deviceFolder + 'dz.png', amountStr: '1 шт'},
        {name: 'датчик включения освещения\u00A0ДВО\u201124', imagePath: this.imageFolder + 'dvo-equipment.png', amountStr: '1 шт'},
        {name: 'кабели, провода, кабель-каналы, расходные материалы', imagePath: this.imageFolder + 'montage-materials.png', amountStr: 'комплект'}
      ]
    },

    {tabName: 'отличия', title: 'ДОПОЛНЕНИЯ РАСШИРЕННОГО ВАРИАНТА', description: 'отличия между расширенным и стандартным вариантом',
      parameterSets: [
        {name: 'отопление', steps: [
            'давление в трубопроводах подачи и обратки',
            'температура воды в обратных трубопроводах дополнительных ветвей',
            'объём воды, поданной на подпитку'
          ]
        },
        {name: 'горячее водоснабжение', steps: [
            'давление в трубопроводах подачи и обратки',
            'температура воды в обратных трубопроводах дополнительных ветвей',
            'объём поданной холодной воды'
          ]
        },
        {name: 'ТЭЦ', steps: [
            'давление в трубопроводах подачи и обратки'
          ]
        },
        {name: 'контуры теплообменников', steps: [
            'давление в трубопроводах'
          ]
        },
        {name: 'теплоузел', steps: [
            'температура и влажность в помещении',
            'потреблённая электрическая энергия',
            'состояние входной двери (открыта или закрыта)',
            'наличие движения людей в помещении',
            'включение освещения в помещении',
            'наличие затопления (протечек)',
            'исчезновение напряжения питания ~220 В'
          ]
        },
        {name: 'оборудование', steps: [
            'состояние циркуляционных насосов (вкл/выкл, потребляемые токи)'
          ]
        }
      ],
      equipments: [
        {name: 'манометр цифровой МЦ\u20111,6', imagePath: this.deviceFolder + 'manometer.png', amountStr: '2-6 шт'},
        {name: 'счётчик импульсов автономный\u00A0СИА\u201124М', imagePath: this.deviceFolder + 'sia.png', amountStr: '1-3 шт'},
        {name: 'извещатель магнитоконтактный', imagePath: this.imageFolder + 'contact-detector.png', amountStr: '1-2 шт'},
        {name: 'датчик тока аналоговый', imagePath: this.imageFolder + 'current-detector.png', amountStr: '2-4 шт'},
        {name: 'датчик температуры\u00A0ДТЦ\u201124М', imagePath: this.deviceFolder + 'dtc.png', amountStr: '2-4 шт'},
        {name: 'датчик влажности и\u00A0температуры', imagePath: this.imageFolder + 'dvtp-equipment.png', amountStr: '2-4 шт'},
        {name: 'датчик затопления\u00A0ДЗ\u201124', imagePath: this.deviceFolder + 'dz.png', amountStr: '1 шт'},
        {name: 'датчик включения освещения\u00A0ДВО\u201124', imagePath: this.imageFolder + 'dvo-equipment.png', amountStr: '1 шт'},
        {name: 'кабели, провода, кабель-каналы, расходные материалы', imagePath: this.imageFolder + 'montage-materials.png', amountStr: 'комплект'}
      ]
    }
  ];

  activeTab: string = this.tabs[0].tabName;

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
}
