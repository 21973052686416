import { Component } from '@angular/core';
import {Solution} from "@app/entity/Solution";

@Component({
  selector: 'app-dispatching-page',
  templateUrl: './dispatching-page.component.html',
  styleUrls: ['./dispatching-page.component.css']
})
export class DispatchingPageComponent {
  solutions: Solution[] = [
    {name: 'артезианских скважин и станций обезжелезивания', routerLink: 'deferrization'},
    {name: 'канализационных насосных станций', routerLink: 'sewerage'},
    {name: 'тепловых пунктов', routerLink: 'heating'},
    {name: 'квартирных счётчиков холодной и горячей воды', routerLink: 'houses'}
  ];
  hoveredSolution: Solution | null = null;

  setHoveredSolution(item: Solution | null) {
    this.hoveredSolution = item;
  }
}
