import {Component, OnInit} from '@angular/core';
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {DocumentationService} from "@app/service/documentation.service";
import {Characteristic} from "@app/entity/Characteristic";

@Component({
  selector: 'app-controller-page',
  templateUrl: './controller-page.component.html',
  styleUrls: ['../../own-block-styles.css', './controller-page.component.css']
})
export class ControllerPageComponent implements OnInit {
  private documentationService: DocumentationService;
  controller: DeviceDocumentation;

  characteristics: Characteristic[] = [
    {bigText: '12 В', middleText: 'постоянный ток', smallText: 'номинальное напряжение'},
    {bigText: '9-15 В', middleText: 'диапазон', smallText: 'питающее напряжение'},
    {bigText: '0.8 А', middleText: 'потребляемый ток', smallText: ''},
    {bigText: 'PIC18F4520', middleText: 'процессор', smallText: '10 млн операций/сек'},
    {bigText: 'обновление ПО', middleText: 'дистанционное', smallText: ''},
    {bigText: 'крепление', middleText: 'шурупом или винтом 3 мм', smallText: 'на плоскую поверхность'},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '131х52х15 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP40', middleText: 'степень защиты', smallText: ''},
    {bigText: '140 г', middleText: 'масса', smallText: ''}
  ];

  centerEquipmentProperties: Characteristic[] = [
    {bigText: 'интерфейсы', middleText: 'RS-485, RS-232C, USART', smallText: ''},
    {bigText: '1024', middleText: 'адресное пространство', smallText: ''}
  ];

  objectEquipmentProperties: Characteristic[] = [
    {bigText: '10 каналов', middleText: 'дискретные входы-выходы', smallText: ''},
    {bigText: '2 канала', middleText: 'дискретные выходы', smallText: 'для управления реле'},
    {bigText: 'RS-232C', middleText: '3 канала', smallText: ''},
    {bigText: 'RS-485', middleText: '2 канала', smallText: ''}
  ];

  powerProperties: Characteristic[] = [
    {bigText: '2 канала', middleText: '+12 В, 0.5 А', smallText: ''},
    {bigText: '1 канал', middleText: '+5 В, 0.1 А', smallText: ''},
    {bigText: '4 канала', middleText: '+5 В, 0.003 А', smallText: ''}
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.controller = this.documentationService.getControllerDocumentation();
  }
}
