<div class="modal-background"></div>

<div class="modal-window-kate"
     [ngStyle]="{'margin-top.px': calcMargin()}">
  <button
    class="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none"
    (click)="modalService.close()"
  >
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  </button>

  <h1 class="modal-title">{{modalTitle}}</h1>
  <app-light-h-line></app-light-h-line>
  <ng-content></ng-content>
</div>
