import {Bullet} from "@app/entity/Bullet";

export class ArticleParagraph {
  text: string | null;
  header: string | null;
  image: string | null;
  bullet: Bullet | null;

  constructor(text?: string, image?: string, header?: string, bullet?: Bullet) {
    this.text = text || null;
    this.image = image || null;
    this.header = header || null;
    this.bullet = bullet || null;
  }
}
