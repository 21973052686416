<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">НАПРАВЛЕНИЯ ДЕЯТЕЛЬНОСТИ</h1>

<div class="business-lines-container block-bottom-padding xl:pb-0 left-side">
  <div
    class="business-grid grid gap-4
           pb-0
           grid-cols-1
           md:grid-cols-2
           xl:grid-cols-3">
    <div
      *ngFor="let direction of directions; let i = index"
      [ngClass]="{
      'business-line-block bg-gray-800 rounded-lg relative overflow-hidden cursor-pointer flex flex-col justify-center': true,
      'xl:col-span-3 h-52 mobile:h-52 lg:h-48 xl:h-44': i === 3,
      'h-52 mobile:h-52 lg:h-48 xl:h-64': i !== 3
    }"
      class="transition hover:shadow-lg flex-grow"
      (click)="navigateTo(direction.link)"
    >
      <div
        class="absolute inset-0 bg-cover bg-center"
        [ngStyle]="{ 'background-image': 'url(' + direction.image + ')',
                     'background-position': 'top' }"
      ></div>
      <div class="absolute inset-0 darken-bg"></div>
      <div class="relative p-4 text-white">
        <h3 class="text-size-title oswald-bold line-height-1-1">
          {{ direction.title }}
        </h3>
        <p *ngIf="direction.description" class="text-size-3 title-description-margin line-height-1-1">
          {{ direction.description }}
        </p>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">НЕМНОГО О НАС</h2>

<div class="block-bottom-padding left-side">
  <div class="about-us-container">
    <div class="grid grid-cols-1 gap-x-8 sm:gap-x-20 gap-y-4 mobile:grid-cols-2 lg:grid-cols-3 text-center">
      <div class="flex flex-col items-center">
        <img src="assets/images/belarus-border%20(1).png" alt="Phone" class="contact-item">
        <p class="text-size-5 text-dark-color oswald normal-line-height mb-0">РАБОТАЕМ С 2008</p>
        <p class="text-size-2 text-dark-color normal-line-height margin-between-title-and-description">по всей Беларуси</p>
      </div>

      <div class="flex flex-col items-center">
        <img src="assets/images/briefcase-with-handle%20(1).png" alt="Address" class="contact-item">
        <p class="text-size-5 text-dark-color oswald normal-line-height mb-0">СПЕЦИАЛИСТЫ</p>
        <p class="text-size-2 text-dark-color normal-line-height margin-between-title-and-description">с опытом 10-50 лет</p>
      </div>

      <div class="flex flex-col items-center mobile:col-span-2 lg:col-span-1">
        <img src="assets/images/star-badge%20(1).png" alt="Email" class="contact-item">
        <p class="text-size-5 text-dark-color oswald normal-line-height mb-0">ПРОДУКЦИЯ</p>
        <p class="text-size-2 text-dark-color normal-line-height margin-between-title-and-description">собственного производства</p>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ДАВАЙТЕ СОТРУДНИЧАТЬ</h2>

<div class="bottom-page-padding left-side">
  <div>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <p *ngIf="requestSent && requestSuccess"
         class="text-green-500 oswald-medium text-size-4 line-height-1-1"
        >Спасибо за заявку!<br/>Скоро свяжемся с вами!</p>
      <p *ngIf="requestSent && !requestSuccess"
         class="text-red-400 oswald-medium text-size-4 line-height-1-1"
        >Возникла ошибка при отправке сообщения =(<br/>Проверьте, пожалуйста, интернет-соединение</p>

      <div *ngIf="!requestSent || !requestSuccess">
        <span class="mb-2 text-size-4 block text-dark-color arsenal-bold">Вас интересует...</span>

        <div class="grid grid-cols-2 gap-x-0 gap-y-1 xs:gap-y-3 sm:grid-cols-3 semi:grid-cols-5 subjects-block">
          <div
            *ngFor="let option of subjectsOfInterest; let i = index"
            (click)="selectSubjectOfInterest(i)"
            [class]="
          'flex flex-col items-center justify-start rounded-xl py-2 px-2 cursor-pointer transition-all ' +
          (selectedSubjectOfInterestIndex === i ? 'border-2 border-yellow-400 bg-yellow-100' : 'border-gray-300 hover:border-gray-400')
        "
          >
            <img src="{{option.iconUrl}}" class="form-choice-icon"/>
            <span class="mt-2 text-size-1 text-center text-dark-color less-line-height" [innerHTML]="option.name"></span>
          </div>
        </div>

        <div
          *ngIf="form.controls.subjectOfInterest.invalid && showAllErrors"
          class="text-red-500 text-size-1 mt-1"
        >
          Выберите один из вариантов
        </div>

        <span class="mt-5 mb-2 text-size-4 block text-dark-color arsenal-bold">Вы интересуетесь как...</span>

        <div class="grid grid-cols-2 gap-x-0 gap-y-1 xs:gap-y-3 sm:grid-cols-4 professions-block">
          <div
            *ngFor="let option of professions; let i = index"
            (click)="selectProfession(i)"
            [class]="
          'flex flex-col items-center justify-start rounded-xl py-2 px-2 cursor-pointer transition-all ' +
          (selectedProfessionIndex === i ? 'border-2 border-yellow-400 bg-yellow-100' : 'border-gray-300 hover:border-gray-400')
        "
          >
            <img src="{{option.iconUrl}}" class="form-choice-icon"/>
            <span class="mt-2 text-size-1 text-center text-dark-color less-line-height" [innerHTML]="option.name"></span>
          </div>
        </div>

        <div
          *ngIf="form.controls.profession.invalid && showAllErrors"
          class="text-red-500 text-size-1 mt-1"
        >
          Выберите один из вариантов
        </div>

          <div>
            <div class="mt-4 pt-0">
              <span class="text-size-3 text-dark-color arsenal-bold">Имя</span>

              <div class="inline-block ml-0 sm:ml-2"
              >
                <input
                  type="text"
                  [formControl]="form.controls.name"
                  placeholder="как к вам обращаться?"
                  class="border rounded-1 w-auto normal-paddings mt-1 ml-3 input-text-size rounded"
                >
              </div>

              <div
                *ngIf="form.controls.name.invalid && (form.controls.name.touched || showAllErrors)"
                class="text-red-500 text-size-1 mt-1"
              >
                Введите своё имя
              </div>
            </div>

            <div class="mt-2 pt-0">
              <span class="text-size-3 text-dark-color arsenal-bold">Телефон</span>

              <div class="inline-block ml-0 sm:ml-2"
              >
                <input
                  type="text"
                  [formControl]="form.controls.phone"
                  placeholder="+375XXXXXXXXX"
                  class="border rounded-1 w-auto normal-paddings mt-1 ml-3 input-text-size rounded"
                  (input)="formatPhoneNumber($event)"
                >
              </div>

              <div
                *ngIf="form.controls.phone.invalid && (form.controls.phone.touched || showAllErrors)"
                class="text-red-500 text-size-1 mt-1"
              >
                Введите номер телефона
              </div>
            </div>
          </div>

        <input type="hidden" [formControl]="form.controls.empty"/>

        <button type="submit" class="mt-4 button-blue-hover-yellow text-size-3"
          >Отправить заявку</button>
        <p class="pt-2 text-size-2 arsenal-bold text-cool-gray-color">на бесплатную консультацию</p>
      </div>
    </form>
  </div>
</div>

<app-footer></app-footer>
