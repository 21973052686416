<div class="pt-3">
  <input
    type="password"
    class="border rounded-1 w-full mt-1 normal-paddings input-text-size"
    placeholder="{{getPlaceholder()}}"
    [formControl]="formElement"
  >

  <app-field-validator
    [formElement]="formElement"
    [showError]="showError"
  ></app-field-validator>
</div>
