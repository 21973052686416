<div class="flex items-center flex-row gap-x-1"
     [ngStyle]="{'margin-top': marginTop + 'px'}"
>
  <app-checkbox
    [text]="fieldName"
    (itemSelection)="onChange($event)"
    [value]="formElement.getRawValue()"
  ></app-checkbox>
  <br/>
</div>
