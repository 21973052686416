<div #carouselContainer class="carousel w-full overflow-hidden relative">
  <button (click)="goToPreviousSlide()" class="button-carousel left-button absolute top-1/2 transform -translate-y-1/2 z-10">←</button>
  <button (click)="goToNextSlide()" class="button-carousel right-button absolute top-1/2 transform -translate-y-1/2 z-10">→</button>
  <div class="flex flex-row items-center pt-2"
       [style.width.px]="containerWidth"
       [style.column-gap.px]="margin"
       [style.margin-left.px]="(parentWidth - containerWidth) / 2"
       [style.min-height.px]="bigImageHeight + 35">
    <div *ngFor="let image of imagesToShow; let i = index"
         [ngStyle]="{'transition': animate ? 'all ' + animationLength + 'ms ease' : 'none'}"
         [style.transform]="getTransformStyleForIndex(i)">
      <a [href]="image">
        <img [src]="image"
             [style.width.px]="getImageWidthByIndex(i)"
             [style.height.px]="getImageHeightByIndex(i)"
             class="rounded-4 object-cover gallery-item"
             [ngStyle]="{'transition': 'all ' + animationLength + 'ms ease'}">
      </a>
    </div>
  </div>
</div>
