<app-header></app-header>

<h1 class="title-bold text-center">КАТАЛОГ УСТРОЙСТВ</h1>

<div *ngIf="devices" class="device-container mx-auto pt-2 pl-2 pr-4 bottom-page-padding">
  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-2 md:gap-y-10 xl:gap-y-12 gap-x-5 lg:gap-x-10">
    <div *ngFor="let device of devices" class="device-card cursor-pointer relative py-4" [routerLink]="'/' + device.routerLink">
      <svg class="grow-on-device-hover ml-16 absolute top-1.5 left-0 z-0" width="210" height="210" xmlns="http://www.w3.org/2000/svg">
        <circle class="hidden xl:inline" cx="100" cy="100" r="100" fill="#FFFFFF" />
        <circle class="hidden md:inline xl:hidden" cx="90" cy="90" r="90" fill="#FFFFFF" />
        <circle class="hidden mobile:inline md:hidden" cx="105" cy="107.5" r="95" fill="#FFFFFF" />
        <circle class="inline mobile:hidden" cx="75" cy="82.5" r="75" fill="#FFFFFF" />
      </svg>

      <div class="flex flex-row items-center space-x-3 mobile:space-x-4">
        <img src="{{device.rightAlignImagePath}}" class="device-image relative" />
        <div class="flex flex-col z-1 align-items-start">
          <h3 class="text-dark-color text-size-6 oswald-medium relative normal-line-height">{{device.shortName}}</h3>
          <p class="text-cool-gray-color text-size-2 oswald relative normal-line-height">{{device.longName}}</p>
          <span class="text-size-2 text-white arsenal-bold bg-blue-color rounded-2 py-0.5 px-2.5 flex-shrink-0 flex-grow-0"
          >{{device.price}} р</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
