<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ДИСПЕТЧЕРИЗАЦИЯ КВАРТИРНЫХ СЧЁТЧИКОВ ВОДЫ</h1>

<div class="block-bottom-padding left-side flex flex-col semi-2:flex-row gap-y-5 gap-x-10">
  <a class="inline-block flex-shrink-0" href="assets/images/mockup5-solution.png">
    <img class="vertical-scheme-image" src="assets/images/mockup5-solution.png"/>
  </a>
  <div class="flex flex-col pr-5">
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text">
      С помощью системы «Дельта» реализуется автоматический дистанционный съём показаний индивидуальных счётчиков
      холодной и горячей воды в многоквартирных жилых домах. В квартирах используются
      механические счётчики воды с импульсными выходами.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">подсчёт и накопление импульсов</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2">
      обеспечивается счётчиками импульсов СИА-24М.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">передача данных на сервер</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2">
      обеспечивается по 4-х проводной линии связи через контроллер «Дельта-5» и GSM-модем.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">получение данных в режиме реального времени</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2 mb-0">
      доступны к просмотру на компьютерах, планшетах и смартфонах.
    </p>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ЧТО МЫ ПРЕДЛАГАЕМ</h2>

<div class="block-bottom-padding left-side">
  <app-offer-block
    [indexesToShow]="[0, 3, 4, 7]"
  ></app-offer-block>
</div>

<h2 class="title-bold-no-left-paddings left-side">ПЕРЕЧЕНЬ АППАРАТУРЫ</h2>

<div class="bottom-page-padding left-side">
  <div class="equipment-grid grid grid-cols-1 semi-2:grid-cols-2 xl:grid-cols-3 gap-x-20 gap-y-8 mobile:gap-y-6">
    <div *ngFor="let equipment of equipments" class="flex flex-row gap-x-4 items-center">
      <div class="equipment-image-div relative">
        <svg
          class="absolute inset-0 z-0 h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <circle class="inline" cx="100" cy="100" r="98" fill="#FFFFFF"/>
        </svg>
        <img src="{{equipment.imagePath}}" class="relative my-auto h-full w-full"/>
      </div>
      <div class="flex flex-col gap-y-2">
        <span class="text-size-equipment oswald-medium text-blue-color line-height-1-1">{{equipment.name}}</span>
        <span class="text-size-equipment oswald text-cool-gray-color line-height-1-1">{{equipment.amountStr}}</span>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
