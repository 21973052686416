import { Component } from '@angular/core';
import {Choice} from "@app/entity/Choice";

@Component({
  selector: 'app-programs-page',
  templateUrl: './programs-page.component.html',
  styleUrls: ['./programs-page.component.css']
})
export class ProgramsPageComponent {
  programs: Choice[] = [
    {name: 'монитор диспетчера с параметрами работы и состояниями оборудования станции обезжелезивания', iconUrl: 'assets/images/mockup10.png'},
    {name: 'монитор диспетчера с параметрами работы и состояниями оборудования центрального теплового пункта', iconUrl: 'assets/images/mockup9.png'},
    {name: 'данные о водопотреблении с квартирных счётчиков', iconUrl: 'assets/images/mockup5.png'},
    {name: 'анализ архива параметров и состояний теплового узла', iconUrl: 'assets/images/mockup3.png'},
    {name: 'модуль диспетчера для контроля текущего состояния теплового узла', iconUrl: 'assets/images/mockup1.png'},
    {name: 'модуль диспетчера для контроля текущего состояния теплового узла', iconUrl: 'assets/images/mockup8.png'},
    {name: 'отчёт по энергопотреблению в участке ЖЭУ', iconUrl: 'assets/images/mockup2.png'},
    {name: 'дистанционное управление регулятором температуры', iconUrl: 'assets/images/mockup6.png'},
    {name: 'монитор диспетчера с параметрами работы и состояниями оборудования артезианских скважин', iconUrl: 'assets/images/mockup4.png'},
    {name: 'монитор диспетчера для контроля канализационных насосных станций', iconUrl: 'assets/images/mockup7.png'},
  ]
}
