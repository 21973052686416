import {Component, OnInit} from '@angular/core';
import {DeviceService} from "@app/service/device.service";
import {Device} from "@app/entity/Device";

@Component({
  selector: 'app-devices-page',
  templateUrl: './devices-page.component.html',
  styleUrls: ['./devices-page.component.css']
})
export class DevicesPageComponent implements OnInit {
  private deviceService: DeviceService;

  devices: Device[];

  constructor(deviceService: DeviceService) {
    this.deviceService = deviceService;
  }

  ngOnInit() {
    this.devices = this.deviceService.getDevices();
  }
}
