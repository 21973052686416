import {Injectable} from '@angular/core';
import {Device} from "@app/entity/Device";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private rightAlignFolder = 'assets/images/devices_right_align/';
  private bottomAlignFolder = 'assets/images/devices_bottom_align/';
  private imageFolder = 'assets/images/devices/';

  constructor() { }

  getDevices(): Device[] {
    return [
      {id: 1, shortName: 'МЦ-1,6', longName: 'манометр цифровой', price: 136, routerLink: 'manometer',
      imagePath: this.imageFolder + 'manometer.png', rightAlignImagePath: this.rightAlignFolder + 'manometer.png', bottomAlignImagePath: this.bottomAlignFolder + 'manometer.png'},
      {id: 2, shortName: 'КЛП Дельта-5', longName: 'контроллер логический программируемый', price: 340, routerLink: 'controller',
        imagePath: this.imageFolder + 'controller.png', rightAlignImagePath: this.rightAlignFolder + 'controller.png', bottomAlignImagePath: this.bottomAlignFolder + 'controller.png'},
      {id: 3, shortName: 'ДЗ-24', longName: 'датчик затопления', price: 83, routerLink: 'detector-flood',
        imagePath: this.imageFolder + 'dz.png', rightAlignImagePath: this.rightAlignFolder + 'dz.png', bottomAlignImagePath: this.bottomAlignFolder + 'dz.png'},
      {id: 4, shortName: 'Ethernet', longName: 'преобразователь ethernet/RS-485', price: 158, routerLink: 'ethernet',
        imagePath: this.imageFolder + 'ethernet.png', rightAlignImagePath: this.rightAlignFolder + 'ethernet.png', bottomAlignImagePath: this.bottomAlignFolder + 'ethernet.png'},
      {id: 5, shortName: 'ДТЦ-24М', longName: 'датчик температуры цифровой', price: 62, routerLink: 'detector-temperature',
        imagePath: this.imageFolder + 'dtc.png', rightAlignImagePath: this.rightAlignFolder + 'dtc.png', bottomAlignImagePath: this.bottomAlignFolder + 'dtc.png'},
      {id: 6, shortName: 'ДТЦП-24М', longName: 'датчик температуры цифровой показывающий', price: 108, routerLink: 'detector-temperature-indicating',
        imagePath: this.imageFolder + 'dtcp.png', rightAlignImagePath: this.rightAlignFolder + 'dtcp.png', bottomAlignImagePath: this.bottomAlignFolder + 'dtcp.png'},
      {id: 7, shortName: 'СИА-24М', longName: 'счётчик импульсов автономный', price: 89, routerLink: 'counter-impulse',
        imagePath: this.imageFolder + 'sia.png', rightAlignImagePath: this.rightAlignFolder + 'sia.png', bottomAlignImagePath: this.bottomAlignFolder+ 'sia.png'}
    ];
  }

  getController() {
    return this.getDeviceById(2);
  }

  getManometer() {
    return this.getDeviceById(1);
  }

  getDetectorFlood() {
    return this.getDeviceById(3);
  }

  getEthernet() {
    return this.getDeviceById(4);
  }

  getDetectorTemperature() {
    return this.getDeviceById(5);
  }

  getDetectorTemperatureShowing() {
    return this.getDeviceById(6);
  }

  getCounterImpulse() {
    return this.getDeviceById(7);
  }

  private getDeviceById(id: number): Device {
    const devices = this.getDevices();
    return devices.find(device => device.id === id);
  }
}
