import { Component } from '@angular/core';
import {Client} from "@app/entity/Client";

@Component({
  selector: 'app-clients-page',
  templateUrl: './clients-page.component.html',
  styleUrls: ['./clients-page.component.css']
})
export class ClientsPageComponent {
  clientsInMinsk: Client[] = [
    {
      name: 'ЖЭС 83,101',
      x: 17,
      y: 50.5
    },
    {
      name: 'ЖЭС 96,121',
      x: 77,
      y: 24.5
    },
    {
      name: 'ЖЭС 43',
      x: 40,
      y: 35
    },
    {
      logoUrl: 'assets/images/aquapark_lebyazhi.png',
      description: 'Аквапарк «Лебяжий»',
      x: 23,
      y: 18
    },
    {
      logoUrl: 'assets/images/hotel_slavyanskaya.png',
      description: 'Гостиница «Славянская»',
      x: 33,
      y: 41
    },
    {
      logoUrl: 'assets/images/bsuir.png',
      description: 'БГУИР',
      x: 55,
      y: 40
    },
    {
      logoUrl: 'assets/images/bseu.png',
      description: 'БГЭУ',
      x: 58,
      y: 57
    },
    {
      logoUrl: 'assets/images/hotel_arena.png',
      description: 'Гостиница «Арена»',
      x: 61,
      y: 76
    }
  ];

  clientsInBelarus: Client[] = [
    {
      name: 'Завод «БелГИПС»',
      x: 51,
      y: 57
    },
    {
      name: 'Борисов-Арена',
      x: 56,
      y: 38
    },
    {
      name: 'КУПП «Боровка»',
      x: 59,
      y: 23
    },
    {
      name: '240+ объектов',
      description: 'Речицкий район, КУП «Речицкий райжилкомхоз»',
      x: 65,
      y: 73
    },
    {
      name: '200+ объектов',
      description: 'Житковичский район, УП «Житковичский коммунальник»',
      x: 46,
      y: 79.5
    },
    {
      name: '40+ объектов',
      description: 'Лоевский район, КЖУП «Лоевский райжилкомхоз»',
      x: 72.5,
      y: 82.5
    },
    {
      name: '50+ объектов',
      description: 'Ельский район, КЖЭУП «Ельское»',
      x: 57,
      y: 88
    },
    {
      name: '14+ станций',
      x: 77,
      y: 67
    },
    {
      name: '5+ станций',
      x: 66,
      y: 53
    },
    {
      name: '3+ станции',
      x: 38,
      y: 30
    },
    {
      name: '5+ станций',
      x: 18,
      y: 52
    },
    {
      name: '5+ станций',
      x: 27,
      y: 69
    }
  ];
}
