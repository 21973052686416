import {Component, OnInit} from '@angular/core';
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {Characteristic} from "@app/entity/Characteristic";
import {BulletItem} from "@app/entity/BulletItem";

@Component({
  selector: 'app-ethernet-page',
  templateUrl: './ethernet-page.component.html',
  styleUrls: ['../../own-block-styles.css', './ethernet-page.component.css']
})
export class EthernetPageComponent implements OnInit {
  private documentationService: DocumentationService;
  ethernet: DeviceDocumentation;
  deviceName = 'ПРЕОБРАЗОВАТЕЛЬ ИНТЕРФЕЙСНЫЙ ETHERNET\u00A0/\u00A0RS\u2011485';

  characteristics: Characteristic[] = [
    {bigText: '12 В', middleText: 'постоянный ток', smallText: 'номинальное напряжение'},
    {bigText: '7-15 В', middleText: 'постоянный ток', smallText: 'питающее напряжение'},
    {bigText: '100 мА', middleText: 'потребляемый ток', smallText: 'от внешнего источника'},
    {bigText: '10 Мбит/с', middleText: 'передача данных', smallText: 'на стороне Ethernet'},
    {bigText: 'до 57\u00A0600 бит/с', middleText: 'передача данных', smallText: 'на стороне RS-485'},
    {bigText: '1 подключение', middleText: 'одновременный доступ', smallText: ''},
    {bigText: '-10°С - +55°С', middleText: 'рабочая температура', smallText: ''},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '80х32х20 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP40', middleText: 'степень защиты', smallText: ''},
    {bigText: '50 г', middleText: 'масса', smallText: ''}
  ];

  modeTypes: BulletItem[] = [
    {title:'основной режим', description:'управление настройками преобразователя через его TCP\u2011порт команд, а также обмен данными с ' +
    'устройством, подключённым к порту RS\u2011485 преобразователя'},
    {title:'прозрачный режим', description:'все данные, которые принимаются на TCP\u2011порт прозрачного режима, ' +
    'перенаправляются на порт RS-485, и наоборот'},
    {title:'режим настройки через порт RS-485', description:'изменение настроек преобразователя с помощью подключения модема к компьютеру ' +
    'через порт RS\u2011485, для чего используется преобразователь USB\u00A0/\u00A0RS\u2011485'}
  ]

  powerProperties: Characteristic[] = [
    {bigText: 'TCP socket', middleText: 'механизм доступа', smallText: 'с удалённого ПК'},
    {bigText: '9-битный обмен', middleText: 'дополнительный режим', smallText: 'установка через RS\u2011485'},
    {bigText: 'поиск модема', middleText: 'поддержка функции', smallText: 'через UDP'},
    {bigText: 'IPv4', middleText: 'поддержка работы', smallText: 'в сетях'}
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.ethernet = this.documentationService.getEthernetDocumentation();
  }
}
