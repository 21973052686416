import {Component, OnInit} from '@angular/core';
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {Characteristic} from "@app/entity/Characteristic";

@Component({
  selector: 'app-counter-impulse-page',
  templateUrl: './counter-impulse-page.component.html',
  styleUrls: ['../../own-block-styles.css', './counter-impulse-page.component.css']
})
export class CounterImpulsePageComponent implements OnInit {
  private documentationService: DocumentationService;
  counterImpulse: DeviceDocumentation;

  characteristics: Characteristic[] = [
    {bigText: '5-30 В', middleText: 'постоянный ток', smallText: 'питающее напряжение'},
    {bigText: '12 мА', middleText: 'потребляемый ток', smallText: 'от внешнего источника'},
    {bigText: '2 мкА', middleText: 'потребляемый ток', smallText: 'от внутреннего источника'},
    {bigText: '2 входа', middleText: 'импульсные', smallText: ''},
    {bigText: 'RS-485', middleText: 'интерфейс связи', smallText: 'с верхним уровнем'},
    {bigText: 'MODBUS RTU', middleText: 'протокол связи', smallText: 'с верхним уровнем'},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '55х36х10 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP40', middleText: 'степень защиты', smallText: ''},
    {bigText: '40 г', middleText: 'масса', smallText: ''}
  ];

  impulseSignals: string[] = [
    'замыкание и размыкание «сухих» электрических контактов',
    'открытые коллектор-эмиттер или исток-сток транзисторов',
    'импульсы положительной полярности амплитудой от\u00A03\u00A0до\u00A024\u00A0В'
  ]

  constructionProperties: string[] = [
    'активный модуль, питаемый внутренними и внешними источниками',
    'схема на печатной плате помещена в прозрачную термоусаживаемую трубку',
    'в перерывах работы внешнего питания работает за счёт внутреннего элемента'
  ];

  advices: string[] = [
    'перед эксплуатацией подсоедините вывод +3В внутреннего элемента питания, так как при поставке он отсоединён во избежание разряда',
    'учтите, что в режиме получения сигналов от транзистора счётчик воспринимает сигналы только при наличии внешнего питания'
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.counterImpulse = this.documentationService.getCounterImpulseDocumentation();
  }
}
