<app-header></app-header>

<p class="pb-10 left-side pr-4">
  <span class="title-bold-no-left-paddings mb-0 pb-0 block line-height-1-2">НАШИ КЛИЕНТЫ С УСТАНОВЛЕННЫМ</span>
  <span class="title-bold-yellow-no-left-paddings mt-0 pt-0 block line-height-1-2">КОМПЛЕКСОМ ДЕЛЬТА</span>
</p>

<div class="flex flex-col lg:flex-row justify-content-center bottom-page-padding">
  <div class="padding-bottom-between-maps">
    <div class="map-container mx-auto">
      <img src="assets/images/Belarus.png" alt="Карта Беларуси" class="map-image" />

      <div class="client" [ngStyle]="{ left: '51%', top: '48%' }">
        <p class="text-center normal-line-height
          text-size-locality-name arsenal-bold text-yellow-color">г. Минск</p>
      </div>
      <div class="client" [ngStyle]="{ left: '15%', top: '20%' }">
        <p class="text-center normal-line-height
          text-size-locality-name arsenal-bold text-yellow-color">Республика<br/>Беларусь</p>
      </div>

      <div
        *ngFor="let client of clientsInBelarus"
        class="client"
        [ngStyle]="{ left: client.x + '%', top: client.y + '%' }"
      >
        <img class="client-logo" *ngIf="client.logoUrl" [src]="client.logoUrl" [alt]="client.description"/>
        <p class="text-center less-line-height client-wide-name text-size-client-name arsenal-bold text-dark-color"
           *ngIf="!client.logoUrl">{{ client.name }}</p>

        <div *ngIf="client.description" class="description-tip less-line-height">
          <span class="text-size-client-description text-dark-color">{{client.description}}</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="map-container mx-auto">
      <img src="assets/images/Minsk.png" alt="Карта Минска" class="map-image" />

      <div class="client" [ngStyle]="{ left: '30%', top: '0%' }">
        <p class="text-center normal-line-height
          text-size-locality-name arsenal-bold text-yellow-color">г. Минск</p>
      </div>

      <div
        *ngFor="let client of clientsInMinsk"
        class="client"
        [ngStyle]="{ left: client.x + '%', top: client.y + '%' }"
      >
        <img class="client-logo" *ngIf="client.logoUrl" [src]="client.logoUrl" [alt]="client.description"/>
        <p class="text-center less-line-height client-narrow-name text-size-client-name arsenal-bold text-dark-color" *ngIf="!client.logoUrl">{{ client.name }}</p>

        <div *ngIf="client.description" class="description-tip less-line-height">
          <span class="text-size-client-description text-dark-color">{{client.description}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
