<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ДИСПЕТЧЕРИЗАЦИЯ ТЕПЛОВЫХ ПУНКТОВ</h1>

<div class="block-bottom-padding left-side">
  <a class="inline-block" href="assets/images/mockup9.png">
    <img class="smallest-scheme-image" src="assets/images/mockup9.png"/>
  </a>
</div>

<div class="bottom-page-padding left-side">
  <div class="flex flex-row gap-x-3">
    <button
      *ngFor="let tab of tabs"
      (click)="setActiveTab(tab.tabName)"
      [ngClass]="activeTab === tab.tabName ? 'bg-blue-color text-white' : 'bg-white text-dark-color'"
      class="px-4 py-2 rounded-2 text-size-2 focus:outline-none transition-colors duration-300"
    >
      {{ tab.tabName }}
    </button>
  </div>

  <div class="relative mt-10">
    <div
      *ngFor="let tab of tabs"
      [ngClass]="{
        '': activeTab === tab.tabName,
        'hidden': activeTab !== tab.tabName
      }"
    >
      <h2 class="oswald-medium text-size-6 text-dark-color mb-1 pr-3">{{tab.title}}</h2>
      <h3 class="oswald text-size-5 text-cool-gray-color pr-3 mb-5">{{tab.description}}</h3>

      <div class="block-bottom-padding">
        <div class="flex flex-col pr-5 gap-y-8">
          <div *ngFor="let parameterSet of tab.parameterSets" class="flex flex-col gap-y-5">
            <p class="text-size-6 text-blue-color oswald mt-0 mb-0 line-height-1-0">{{parameterSet.name}}</p>
            <div class="flex flex-col gap-y-3 pr-5">
              <div *ngFor="let parameter of parameterSet.steps; let i = index">
                <div class="relative flex flex-row items-center gap-x-2 md:gap-x-3">
                  <span class="text-blue-color oswald-medium smaller-bullet-digit">{{i + 1}}</span>
                  <span class="text-dark-color text-size-3 inline-block line-height-1-1 bullet-text">{{parameter}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="tab !== tabs[tabs.length - 1]">
        <h2 class="title-bold-no-left-paddings">ЧТО МЫ ПРЕДЛАГАЕМ</h2>
        <div class="block-bottom-padding">
          <app-offer-block></app-offer-block>
        </div>
      </div>

      <h2 class="title-bold-no-left-paddings">ПЕРЕЧЕНЬ АППАРАТУРЫ</h2>

      <div>
        <div class="equipment-grid grid grid-cols-1 semi-2:grid-cols-2 xl:grid-cols-3 gap-x-20 gap-y-8 mobile:gap-y-6">
          <div *ngFor="let equipment of tab.equipments" class="flex flex-row gap-x-4 items-center">
            <div class="equipment-image-div relative">
              <svg
                class="absolute inset-0 z-0 h-full w-full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 200 200"
              >
                <circle class="inline" cx="100" cy="100" r="98" fill="#FFFFFF"/>
              </svg>
              <img src="{{equipment.imagePath}}" class="relative my-auto h-full w-full"/>
            </div>
            <div class="flex flex-col gap-y-2">
              <span class="text-size-equipment oswald-medium text-blue-color line-height-1-1">{{equipment.name}}</span>
              <span class="text-size-equipment oswald text-cool-gray-color line-height-1-1">{{equipment.amountStr}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>
