import {Component} from '@angular/core';
import {Instruction} from "@app/entity/Instruction";
import {Equipment} from "@app/entity/Equipment";

@Component({
  selector: 'app-deferrization',
  templateUrl: './deferrization.component.html',
  styleUrls: ['../../own-block-styles.css', './deferrization.component.css']
})
export class DeferrizationComponent {
  private imageFolder = 'assets/images/';
  private deviceFolder = this.imageFolder + 'devices/';

  parameterSets: Instruction[] = [
    {name: 'телеизмерение', steps: [
        'давление воды на выходе артезианской скважины или на входе станции обезжелезивания',
        'давление воды потребителю',
        'объём поднятой воды',
        'объём воды на промывку фильтров',
        'температура в помещении',
        'потреблённая электрическая энергия (общая, на подъем воды, на обогрев помещений)'
      ]
    },
    {name: 'телесигнализация', steps: [
        'открытие дверей в помещении',
        'исчезновение напряжения питания ~220 В',
        'состояние двигателя насоса (вкл/выкл, потребляемый ток)',
        'сигнализация затопления'
      ]
    },
    {name: 'телеуправление', steps: [
        'включить или выключить насос',
        'внеплановая промывка'
      ]
    },
  ];

  equipments: Equipment[] = [
    {name: 'контроллер «Дельта\u20115» с\u00A0ПО', imagePath: this.deviceFolder + 'controller.png', amountStr: '1 шт'},
    {name: 'GSM\u2011модем IRZ ATM31\u2011485', imagePath: this.imageFolder + 'modem-equipment.png', amountStr: '1 шт'},
    {name: 'блок питания с\u00A0аккумулятором', imagePath: this.imageFolder + 'block-power.png', amountStr: '1 шт'},
    {name: 'манометр цифровой МЦ\u20111,6', imagePath: this.deviceFolder + 'manometer.png', amountStr: '2 шт'},
    {name: 'счётчик импульсов автономный\u00A0СИА\u201124М', imagePath: this.deviceFolder + 'sia.png', amountStr: '2-3 шт'},
    {name: 'извещатель магнитоконтактный', imagePath: this.imageFolder + 'contact-detector.png', amountStr: '1-2 шт'},
    {name: 'датчик тока аналоговый', imagePath: this.imageFolder + 'current-detector.png', amountStr: '1 шт'},
    {name: 'датчик температуры\u00A0ДТЦ\u201124М', imagePath: this.deviceFolder + 'dtc.png', amountStr: '1-2 шт'},
    {name: 'датчик затопления\u00A0ДЗ\u201124', imagePath: this.deviceFolder + 'dz.png', amountStr: '1-2 шт'},
    {name: 'кабели, провода, кабель-каналы, расходные материалы', imagePath: this.imageFolder + 'montage-materials.png', amountStr: 'комплект'}
  ]
}
