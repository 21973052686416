import {ArticleParagraph} from "@app/entity/ArticleParagraph";

export class Article {
  id: number;
  title: string;
  description: string;
  iconUrl: string;
  topImages: string[];
  introduction: string;
  paragraphs: ArticleParagraph[];
  conclusion: string;
}
