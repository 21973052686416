import { Component } from '@angular/core';

@Component({
  selector: 'app-light-h-line',
  templateUrl: './light-h-line.component.html',
  styleUrls: ['./light-h-line.component.css']
})
export class LightHLineComponent {

}
