import {Component} from '@angular/core';
import {YandexMetrikaService} from "@app/service/yandex-metrika.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = 'Багора';

  constructor() {
  }
}
