<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">СЧЁТЧИК ИМПУЛЬСОВ АВТОНОМНЫЙ СИА&#8209;24</h1>

<div class="block-bottom-padding left-side">
  <div class="flex flex-col lg:flex-row gap-y-8 gap-x-8">
    <div class="device-image-div relative">
      <svg
        class="grow-on-device-hover absolute inset-0 z-0 h-full w-full"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
      >
        <circle class="inline" cx="100" cy="100" r="98" fill="#FFFFFF"/>
      </svg>
      <img src="{{counterImpulse.device.imagePath}}" class="relative my-auto object-scale-down h-full w-full"/>
    </div>

    <div class="flex flex-col items-start justify-content-between">
      <div class="flex flex-col items-start">
        <p class="description-text-device text-size-3 line-height-1-2 text-dark-color">
          предназначен для подсчёта количества импульсов, поступающих с импульсных выходов
          расходомеров жидкостей, газов или электроэнергии, и выдачи накопленных сумм
          в аппаратуру верхнего уровня по линии связи.
        </p>
        <span class="text-size-3 text-white arsenal-bold bg-blue-color rounded-2 py-1 px-3 flex-shrink-0 flex-grow-0">
          {{counterImpulse.device.price}} р</span>
      </div>
      <div class="document-list pt-3 transition-all overflow-hidden space-y-1 grid grid-cols-1 2sm:grid-cols-2 2xl:grid-cols-3 gap-y-2 gap-x-3">
        <div *ngFor="let doc of counterImpulse.documents" class="common-document flex flex-row space-x-1.5 cursor-pointer items-center relative">
          <img src="{{doc.iconPath}}" class="document-icon"/>
          <span class="text-size-0 text-dark-color normal-line-height py-2.5">{{doc.documentName}}</span>
          <a href="{{doc.documentPath}}" class="absolute inset-0"></a>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ</h2>

<div class="block-bottom-padding left-side">
  <div class="characteristics-grid grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-5 gap-y-8 mobile:gap-y-10 lg:gap-y-12">
    <div *ngFor="let characteristic of characteristics" class="flex flex-col gap-y-2">
      <span class="oswald text-blue-color title-characteristic-size line-height-0-8 mobile:line-height-0-9">{{characteristic.bigText}}</span>
      <span class="oswald text-cool-gray-color description-characteristic-size mt-1 line-height-0-7 mobile:line-height-0-9">{{characteristic.middleText}}</span>
      <span class="oswald text-cool-gray-color description-characteristic-size line-height-0-7 mobile:line-height-0-9">{{characteristic.smallText}}</span>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">КОНСТРУКЦИЯ УСТРОЙСТВА</h2>

<div class="block-bottom-padding left-side">
  <div class="construction-grid-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-6">
    <div *ngFor="let characteristic of constructionProperties" class="flex flex-row">
      <span class="text-blue-color oswald text-size-4 border-blue-color border-2 line-height-1-2 py-3 px-4 rounded-3">{{characteristic}}</span>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ВИДЫ ВОСПРИНИМАЕМЫХ ИМПУЛЬСНЫХ СИГНАЛОВ</h2>

<div class="block-bottom-padding left-side">
  <div class="flex flex-col gap-y-3 pr-5">
    <div *ngFor="let signal of impulseSignals; let i = index">
      <div class="relative flex flex-row items-center gap-x-3 md:gap-x-4">
        <span class="text-blue-color oswald-medium bullet-digit">{{i + 1}}</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-2 bullet-text">{{signal}}</span>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">НАЗНАЧЕНИЕ ВЫВОДОВ</h2>

<div class="block-bottom-padding left-side">
  <div class="flex flex-col">
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text">Модуль снабжён клеммной колодкой
      с винтовыми зажимами с шагом 3.5 мм:
    </p>
    <a href="assets/images/siatable.jpg">
      <img src="assets/images/siatable.jpg" class="output-table-image"/>
    </a>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ИНДИКАЦИЯ СОСТОЯНИЯ</h2>

<div class="block-bottom-padding left-side">
  <div class="flex flex-col pr-5">
    <p class="text-size-6 text-blue-color oswald mt-0 mb-0 line-height-1-0">при подключении только внутреннего питания</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2">
      индикатор 1 раз мигает и погасает.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">при подключении внешнего питания</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2">
      индикатор с помощью световых импульсов передаёт 8-битный код своего сетевого адреса, после чего
      индикатор остаётся зажжённым.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">при обмене данных по шине RS-485</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2 mb-0">
      индикатор однократно кратковременно гасится.
    </p>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">РЕКОМЕНДАЦИИ</h2>

<div class="block-bottom-padding left-side">
  <div class="flex flex-col gap-y-3 pr-5">
    <div *ngFor="let advice of advices">
      <div class="relative flex flex-row items-center gap-x-3 mobile:gap-x-4 md:gap-x-5">
        <img src="assets/images/exclamation.png" class="notice-icon"/>
        <span class="text-dark-color text-size-3 inline-block line-height-1-2 bullet-text">{{advice}}</span>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ГАРАНТИЯ</h2>

<div class="bottom-page-padding left-side">
  <div class="flex flex-col">
    <p class="text-size-6 text-blue-color oswald mt-0 mb-0 line-height-0-9">24 месяца</p>
    <p class="text-size-3 text-dark-color line-height-1-2 description-plain-text mt-2 mb-0">- гарантийный срок эксплуатации
      датчика с момента его реализации при соблюдении потребителем правил эксплуатации. Гарантии не распространяются
      на счётчики, имеющие механические повреждения защитного кожуха или открытых элементов.
    </p>
  </div>
</div>

<app-footer></app-footer>
