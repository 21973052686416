import { Component } from '@angular/core';
import {Choice} from "@app/entity/Choice";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {TelegramService} from "@app/service/telegram.service";
import {FormRequest} from "@app/entity/FormRequest";

@Component({
  selector: 'app-customization-page',
  templateUrl: './customization-page.component.html',
  styleUrls: ['./customization-page.component.css']
})
export class CustomizationPageComponent {
  private telegramService: TelegramService;

  subjectsOfInterest: Choice[] = [
    { name: 'стенд для<br/>контроля\u00A0жгутов', iconUrl: 'assets/images/wires-stand.png' },
    { name: 'блоки для металло-детекторов', iconUrl: 'assets/images/metal-detector.png' },
    { name: 'разработка<br/>ПО', iconUrl: 'assets/images/software-info.png' },
    { name: 'другой<br/>вариант', iconUrl: 'assets/images/other-choice.png' }
  ];

  professions: Choice[] = [
    { name: 'руководитель', iconUrl: 'assets/images/who_boss.png' },
    { name: 'инженер', iconUrl: 'assets/images/who_engineer.png' },
    { name: 'специалист<br/>по закупкам', iconUrl: 'assets/images/who_analyst.png' },
    { name: 'частное<br/>лицо', iconUrl: 'assets/images/who_individual.png' }
  ];

  examples: Choice[] = [
    { name: 'стенд для контроля электрических соединений жгутов', iconUrl: 'assets/images/stand-photo.png' },
    { name: 'электронные блоки и\u00A0ПО для охранных и\u00A0технологических металлодетекторов', iconUrl: 'assets/images/metal-detector-photo.png' },
    { name: 'ПО для конвертации данных из протокола устройств в протокол Modbus', iconUrl: 'assets/images/software-photo.png' }
  ];

  showAllErrors = false;
  requestSent = false;
  requestSuccess = true;

  selectedSubjectOfInterestIndex: number | null;
  selectedProfessionIndex: number | null;

  form = new FormGroup({
    subjectOfInterest: new FormControl<Choice>(null, [
      Validators.required
    ]),
    profession: new FormControl<Choice>(null, [
      Validators.required
    ]),
    name: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(50)
    ]),
    phone: new FormControl<string>('+375', [
      Validators.required,
      this.phoneValidator()
    ]),
    empty: new FormControl<string>(null)
  });

  constructor(telegramService: TelegramService) {
    this.telegramService = telegramService;
  }

  submit(): void {
    if (this.isAnyErrorInFields()) {
      this.showAllErrors = true;
      return;
    }

    const formRequest = this.collectDataFromForm();
    this.telegramService.sendMessage(formRequest)
      .subscribe({
        next: () => {
          this.requestSent = true;
          this.requestSuccess = true;
        },
        error: () => {
          this.requestSent = true;
          this.requestSuccess = false;
        },
      });
  }

  private isAnyErrorInFields(): boolean {
    return this.form.controls.name.invalid || this.form.controls.phone.invalid ||
      this.form.controls.profession.invalid || this.form.controls.subjectOfInterest.invalid;
  }

  private collectDataFromForm(): FormRequest {
    const data = new FormRequest();
    data.name = this.form.controls.name.value;
    data.phone = this.form.controls.phone.value;
    data.subjectOfInterest = 'изготовление на заказ, ' + this.form.controls.subjectOfInterest.value.name.replaceAll('<br/>', ' ');
    data.profession = this.form.controls.profession.value.name.replaceAll('<br/>', ' ');
    return data;
  }

  selectProfession(index: number) {
    this.selectedProfessionIndex = index;
    this.form.patchValue({ profession: this.professions[index] });
  }

  selectSubjectOfInterest(index: number) {
    this.selectedSubjectOfInterestIndex = index;
    this.form.patchValue({ subjectOfInterest: this.subjectsOfInterest[index] });
  }

  formatPhoneNumber(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/[^0-9+]/g, '');

    if (value.length < '+375'.length) {
      value = '+375';
    }

    if (!value.startsWith('+375')) {
      value = '+375' + value.replace(/^\+375/, '');
    }

    if (value.length > 13) {
      value = value.slice(0, 13);
    }

    input.value = value;
    this.form.get('phone')?.setValue(value, { emitEvent: false });
  }

  phoneValidator() {
    return (control: any) => {
      const phoneRegex = /^\+375\d{9}$/;
      return phoneRegex.test(control.value) ? null : { invalidPhone: true };
    };
  }
}
