<div class="pt-3">
  <input
    type="text"
    class="border rounded-1 w-{{width}} mt-1 normal-paddings input-text-size"
    placeholder="{{getPlaceholder()}}"
    [formControl]="formElement"
  >

  <app-field-validator
    [formElement]="formElement"
    [showError]="showError"
    [additionalNote]="additionalNote"
  ></app-field-validator>
</div>
