<!-- ДЕСКТОПНОЕ И ПЛАНШЕТНОЕ МЕНЮ -->
<div class="hidden sm:block bg-white menu-shadow">
  <div class="mx-auto pl-6 pr-12 flex flex-col lg:flex-row items-center justify-between pt-2 pb-3">

    <div class="flex flex-row items-center justify-content-between w-full lg:w-auto">
      <img src="assets/images/logo_description.png" alt="БАГОРА" class="logo-horizontal cursor-pointer"
           [routerLink]="['/']">

      <div class="hidden flex-col md:flex-row lg:hidden sm:flex items-center md:mt-0 buttons-block-margin">
        <a href="tel:+375296542973" class="hidden md:inline-block">
          <img class="menu-icon grow-on-hover-110" src="assets/images/phone_blue.png" />
        </a>
        <a href="mailto:info@bagora.by" class="hidden md:inline-block ml-1">
          <img class="menu-icon grow-on-hover-110" src="assets/images/envelope_blue.png" />
        </a>
        <button
          class="md:ml-6 bg-blue-color text-size-3 text-white px-6 py-2 rounded-full grow-on-hover-105"
          [routerLink]="['/contact']">
          контакты
        </button>

        <div class="flex space-x-1 mt-2 items-center md:hidden">
          <a href="tel:+375296542973">
            <img class="menu-icon grow-on-hover-110" src="assets/images/phone_blue.png" />
          </a>
          <a href="mailto:info@bagora.by">
            <img class="menu-icon grow-on-hover-110" src="assets/images/envelope_blue.png" />
          </a>
        </div>
      </div>
    </div>

    <nav class="menu-navigation">
      <div class="relative group">
        <button class="text-dark-color text-yellow-on-hover menu-font ease-color-transition flex items-center py-2">
          услуги
          <svg class="ml-1 w-4 h-4 relative top-[2px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
        <ul
          class="z-10 absolute hidden group-hover:block hover:block bg-white shadow-lg rounded w-52 transition duration-200 py-3 px-0">
          <li class="submenu-li">
            <a routerLink="/devices" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">каталог устройств</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/dispatching" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">диспетчеризация</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/calibration" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">поверка</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/customization" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">изготовление под заказ</a>
          </li>
        </ul>
      </div>

      <div class="relative group">
        <button class="text-dark-color text-yellow-on-hover menu-font ease-color-transition flex items-center py-2">
          подробнее
          <svg class="ml-1 w-4 h-4 relative top-[2px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
        <ul
          class="z-10 absolute hidden group-hover:block hover:block bg-white shadow-lg rounded w-52 transition duration-200 py-3 px-0">
          <li class="submenu-li">
            <a routerLink="/documentation" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">документация</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/programs" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">ПО</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/clients" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">наши клиенты</a>
          </li>
          <!--<li class="submenu-li">
            <a routerLink="/history" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">наша история</a>
          </li>-->
        </ul>
      </div>

      <div class="relative group">
        <button class="text-dark-color text-yellow-on-hover menu-font ease-color-transition flex items-center py-2">
          пресс-центр
          <svg class="ml-1 w-4 h-4 relative top-[2px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
        <ul
          class="z-10 absolute hidden group-hover:block hover:block bg-white shadow-lg rounded w-52 transition duration-200 py-3 px-0">
          <li class="submenu-li">
            <a routerLink="/gallery" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">галерея</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/instructions" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">видеоинструкции</a>
          </li>
          <li class="submenu-li">
            <a routerLink="/articles" class="block px-4 py-2 text-dark-color line-height-1-1 submenu-font">статьи</a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="hidden lg:flex flex-col xl:flex-row items-center xl:justify-end space-y-4 xl:space-y-0">
      <a href="tel:+375447107865" class="hidden xl:inline-block">
        <img class="menu-icon grow-on-hover-110" src="assets/images/phone_blue.png" />
      </a>

      <a href="mailto:info@bagora.by" class="hidden xl:inline-block ml-1">
        <img class="menu-icon grow-on-hover-110" src="assets/images/envelope_blue.png" />
      </a>

      <button
        class="xl:ml-6 bg-blue-color text-size-3 text-white px-6 py-2 rounded-full grow-on-hover-105"
        [routerLink]="['/contact']">
        контакты
      </button>

      <div class="flex space-x-1 mt-2 justify-center xl:hidden">
        <a href="tel:+375296542973">
          <img class="menu-icon grow-on-hover-110" src="assets/images/phone_blue.png" />
        </a>
        <a href="mailto:info@bagora.by">
          <img class="menu-icon grow-on-hover-110" src="assets/images/envelope_blue.png" />
        </a>
      </div>
    </div>
  </div>
</div>

<!-- МОБИЛЬНОЕ МЕНЮ -->
<div class="block sm:hidden relative">
  <div class="flex justify-between items-center px-4 pt-2 pb-3 bg-white menu-shadow">
    <img src="assets/images/logo_description.png" alt="БАГОРА" class="logo-horizontal cursor-pointer"
         [routerLink]="['/']">
    <img
      src="assets/images/menu.png"
      (click)="toggleMenu()"
      class="burger-menu-open-icon absolute top-3 right-5"
    />
  </div>

  <nav
    [class.open]="isMenuOpen"
    [class.closed]="!isMenuOpen"
    class="fixed inset-x-0 inset-y-0 menu-blur z-50 transform transition-transform duration-300"
  >
    <h2 class="text-size-7 arsenal-bold text-blue-color text-center w-full mt-0.5 px-4 py-3">МЕНЮ</h2>
    <img
      src="assets/images/close.png"
      (click)="toggleMenu()"
      class="burger-menu-close-icon absolute top-5 right-5"
    />

    <div class="flex flex-row items-start space-x-5 w-5/6 mx-auto">
      <div class="flex-1">
        <h3 class="arsenal-bold text-cool-gray-color text-size-4">УСЛУГИ</h3>
        <ul class="pl-0 mt-1 space-y-1">
          <li><a routerLink="/devices" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >каталог устройств</a></li>
          <li><a routerLink="/dispatching" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >диспетчеризация</a></li>
          <li><a routerLink="/calibration" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >поверка</a></li>
          <li><a routerLink="/customization" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >изготовление под заказ</a></li>
        </ul>
      </div>

      <div class="flex-1">
        <h3 class="arsenal-bold text-cool-gray-color text-size-4">ПОДРОБНЕЕ</h3>
        <ul class="pl-0 mt-1 space-y-1">
          <li><a routerLink="/documentation" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >документация</a></li>
          <li><a routerLink="/programs" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >ПО</a></li>
          <li><a routerLink="/clients" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >наши клиенты</a></li>
          <!--<li><a routerLink="/history" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >наша история</a></li>-->
        </ul>

        <h3 class="mt-12 arsenal-bold text-cool-gray-color text-size-4">ПРЕСС-ЦЕНТР</h3>
        <ul class="pl-0 mt-1 space-y-1">
          <li><a routerLink="/gallery" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >галерея</a></li>
          <li><a routerLink="/instructions" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >видеоинструкции</a></li>
          <li><a routerLink="/articles" class="text-dark-color submenu-font normal-line-height text-yellow-on-hover ease-color-transition"
            >статьи</a></li>
        </ul>
      </div>
    </div>

    <div class="absolute bottom-0 inset-x-0">
      <div class="flex flex-row items-center space-x-4 py-3 w-5/6 mx-auto">
        <div class="flex-1">
          <button
            class="bg-blue-color text-size-2 text-white px-6 py-2 rounded-full grow-on-hover-105"
            [routerLink]="['/contact']">
            контакты
          </button>
        </div>

        <div class="flex-1 flex flex-row items-center space-x-0.5">
          <a href="tel:+375296542973">
            <img class="menu-icon grow-on-hover-110" src="assets/images/phone_blue.png" />
          </a>
          <a href="mailto:info@bagora.by">
            <img class="menu-icon grow-on-hover-110" src="assets/images/envelope_blue.png" />
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>
