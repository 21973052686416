import { Injectable } from '@angular/core';
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {DeviceService} from "@app/service/device.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  public readonly documentFolder = 'assets/documents/';
  public readonly iconFolder = 'assets/images/';

  private deviceService: DeviceService;

  constructor(deviceService: DeviceService) {
    this.deviceService = deviceService;
  }

  getDocumentations(): DeviceDocumentation[] {
    const devices = this.deviceService.getDevices();
    return [
      {device: devices[0], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'ManometerPassport-2023.pdf', iconPath: this.iconFolder + 'passport.png'},
          {documentName: 'Сертификат', documentPath: this.documentFolder + 'ManometerCertificate.jpg', iconPath: this.iconFolder + 'certificate.png'},
          {documentName: 'Декларация о соответствии требованиям (ТРТС 020/2011)', documentPath: this.documentFolder + 'ManometerDeclaration.pdf', iconPath: this.iconFolder + 'declaration.png'},
          {documentName: 'Руководство пользователя', documentPath: this.documentFolder + 'ManometerOperationsManual-13.09.2023.pdf', iconPath: this.iconFolder + 'instruction.png'},
          {documentName: 'Протокол информационного обмена', documentPath: this.documentFolder + 'ManometerProtocol.pdf', iconPath: this.iconFolder + 'protocol.png'},
          {documentName: 'Протокол - общая часть', documentPath: this.documentFolder + 'Protocol_1.6_Main.pdf', iconPath: this.iconFolder + 'protocol.png'},
          {documentName: 'Протокол манометра', documentPath: this.documentFolder + 'Protocol_1.6.pdf', iconPath: this.iconFolder + 'protocol.png'},
          {documentName: 'Программа для визуализации данных', documentPath: this.documentFolder + 'Visual_Manometr_2.zip', iconPath: this.iconFolder + 'app.png'}
        ]},
      {device: devices[1], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'KLP-Passport_2022.pdf', iconPath: this.iconFolder + 'passport.png'}
        ]},
      {device: devices[2], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'DZ-24_Passport.pdf', iconPath: this.iconFolder + 'passport.png'}
        ]},
      {device: devices[3], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'Ethernet-RS485_Passport.pdf', iconPath: this.iconFolder + 'passport.png'},
          {documentName: 'Руководство пользователя', documentPath: this.documentFolder + 'Ethernet-RS485_Manual.pdf', iconPath: this.iconFolder + 'instruction.png'},
          {documentName: 'Протокол связи', documentPath: this.documentFolder + 'Ethernet-RS485_Protocol.pdf', iconPath: this.iconFolder + 'protocol.png'},
          {documentName: 'Программа для конфигурирования', documentPath: this.documentFolder + 'Server.zip', iconPath: this.iconFolder + 'app.png'}
        ]},
      {device: devices[4], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'DTC-24M_Passport.pdf', iconPath: this.iconFolder + 'passport.png'}
        ]},
      {device: devices[5], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'DTCP-24M_Passport_2022.pdf', iconPath: this.iconFolder + 'passport.png'},
          {documentName: 'Протокол', documentPath: this.documentFolder + 'Protocol_DTCP-24M_2022.pdf', iconPath: this.iconFolder + 'protocol.png'}
        ]},
      {device: devices[6], documents: [
          {documentName: 'Паспорт', documentPath: this.documentFolder + 'Sia24_Passport.pdf', iconPath: this.iconFolder + 'passport.png'},
          {documentName: 'Протокол - общая часть', documentPath: this.documentFolder + 'Sia_Protocol1.pdf', iconPath: this.iconFolder + 'protocol.png'},
          {documentName: 'Протокол - СИА-24М', documentPath: this.documentFolder + 'Sia_Protocol2.pdf', iconPath: this.iconFolder + 'protocol.png'}
        ]}
    ];
  }

  getControllerDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getController();
    return this.getDocumentationById(device.id);
  }

  getManometerDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getManometer();
    return this.getDocumentationById(device.id);
  }

  getEthernetDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getEthernet();
    return this.getDocumentationById(device.id);
  }

  getDetectorTemperatureDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getDetectorTemperature();
    return this.getDocumentationById(device.id);
  }

  getDetectorFloodDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getDetectorFlood();
    return this.getDocumentationById(device.id);
  }

  getCounterImpulseDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getCounterImpulse();
    return this.getDocumentationById(device.id);
  }

  getDetectorTemperatureIndicatingDocumentation(): DeviceDocumentation {
    const device = this.deviceService.getDetectorTemperatureShowing();
    return this.getDocumentationById(device.id);
  }

  private getDocumentationById(id: number): DeviceDocumentation {
    const documentations = this.getDocumentations();
    return documentations.find(documentation => documentation.device.id === id);
  }
}
