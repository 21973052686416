<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ</h1>

<div class="bottom-page-padding left-side">
  <div class="programs-grid grid grid-cols-1 xl:grid-cols-2 gap-x-20 gap-y-16 xl:gap-y-20">
    <div *ngFor="let program of programs" class="flex flex-col gap-y-5">
      <div class="flex flex-col gap-y-2">
        <span class="text-size-programs oswald text-blue-color line-height-1-1">{{program.name}}</span>
      </div>
      <div class="programs-image-div relative">
        <svg
          class="absolute inset-0 z-0 h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <circle class="inline" cx="100" cy="100" r="98" fill="#FFFFFF"/>
        </svg>
        <a class="inline-block relative my-auto h-full w-full" href="{{program.iconUrl}}">
          <img src="{{program.iconUrl}}" class="h-full w-full"/>
        </a>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
