import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {APP_BASE_HREF, CommonModule, NgOptimizedImage} from "@angular/common";
import {AppRoutingModule} from "./app-routing.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MainPageComponent } from './pages/main-page/main-page.component';
import { MiddleControlComponent } from './little-components/middle-control/middle-control.component';

import { ErrorParagraphComponent } from './little-components/error-paragraph/error-paragraph.component';
import { TextFieldPlaceholderComponent } from './little-components/text-field-placeholder/text-field-placeholder.component';
import { FieldValidatorComponent } from './little-components/field-validator/field-validator.component';
import { TextFieldPasswordPlaceholderComponent } from './little-components/text-field-password-placeholder/text-field-password-placeholder.component';
import { ModalWindowCreateComponent } from './modal/modal-window-create/modal-window-create.component';
import { HorizontalLineComponent } from './little-components/horizontal-line/horizontal-line.component';
import { LightHLineComponent } from './little-components/light-h-line/light-h-line.component';
import { TextFieldComponent } from './little-components/text-field/text-field.component';
import { FieldNameComponent } from './little-components/field-name/field-name.component';
import { CheckboxSingleComponent } from './little-components/checkbox-single/checkbox-single.component';
import { CheckboxComponent } from './little-components/checkbox/checkbox.component';
import { ChoiceDateComponent } from './little-components/choice-date/choice-date.component';
import { ComboboxComponent } from './little-components/combobox/combobox.component';
import { FormControlPipe } from './pipe/form-control.pipe';
import { GroupBoxComponent } from './little-components/group-box/group-box.component';
import { CapitalizePipe } from './pipe/capitalize.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { DevicesPageComponent } from './pages/devices-page/devices-page.component';
import { ManometerPageComponent } from './pages/devices/manometer-page/manometer-page.component';
import { ControllerPageComponent } from './pages/devices/controller-page/controller-page.component';
import { DetectorFloodPageComponent } from './pages/devices/detector-flood-page/detector-flood-page.component';
import { EthernetPageComponent } from './pages/devices/ethernet-page/ethernet-page.component';
import { DetectorTemperaturePageComponent } from './pages/devices/detector-temperature-page/detector-temperature-page.component';
import { DetectorTemperatureIndicatingPageComponent } from './pages/devices/detector-temperature-indicating-page/detector-temperature-indicating-page.component';
import { CounterImpulsePageComponent } from './pages/devices/counter-impulse-page/counter-impulse-page.component';
import { DocumentationPageComponent } from './pages/documentation-page/documentation-page.component';
import { ClientsPageComponent } from './pages/clients-page/clients-page.component';
import { DispatchingPageComponent } from './pages/dispatching-page/dispatching-page.component';
import { DeferrizationComponent } from './pages/solutions/deferrization/deferrization.component';
import { HousesComponent } from './pages/solutions/houses/houses.component';
import { SewerageComponent } from './pages/solutions/sewerage/sewerage.component';
import { HeatingComponent } from './pages/solutions/heating/heating.component';
import { CalibrationComponent } from './pages/calibration/calibration.component';
import { ArrowTitleComponent } from './little-components/arrow-title/arrow-title.component';
import { OfferBlockComponent } from './components/offer-block/offer-block.component';
import { CustomizationPageComponent } from './pages/customization-page/customization-page.component';
import { ProgramsPageComponent } from './pages/programs-page/programs-page.component';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { VideoPageComponent } from './pages/video-page/video-page.component';
import { ArticlesPageComponent } from './pages/articles-page/articles-page.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { SingleArticlePageComponent } from './pages/articles/single-article-page/single-article-page.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    MiddleControlComponent,
    ErrorParagraphComponent,
    TextFieldPlaceholderComponent,
    FieldValidatorComponent,
    TextFieldPasswordPlaceholderComponent,
    ModalWindowCreateComponent,
    HorizontalLineComponent,
    LightHLineComponent,
    TextFieldComponent,
    FieldNameComponent,
    CheckboxSingleComponent,
    CheckboxComponent,
    ChoiceDateComponent,
    ComboboxComponent,
    FormControlPipe,
    GroupBoxComponent,
    CapitalizePipe,
    FooterComponent,
    HeaderComponent,
    ContactPageComponent,
    DevicesPageComponent,
    ManometerPageComponent,
    ControllerPageComponent,
    DetectorFloodPageComponent,
    EthernetPageComponent,
    DetectorTemperaturePageComponent,
    DetectorTemperatureIndicatingPageComponent,
    CounterImpulsePageComponent,
    DocumentationPageComponent,
    ClientsPageComponent,
    DispatchingPageComponent,
    DeferrizationComponent,
    HousesComponent,
    SewerageComponent,
    HeatingComponent,
    CalibrationComponent,
    ArrowTitleComponent,
    OfferBlockComponent,
    CustomizationPageComponent,
    ProgramsPageComponent,
    HistoryPageComponent,
    GalleryPageComponent,
    VideoPageComponent,
    ArticlesPageComponent,
    ImageCarouselComponent,
    SingleArticlePageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgOptimizedImage
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
