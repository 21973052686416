<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ИЗГОТОВЛЕНИЕ ПОД ЗАКАЗ</h1>

<p class="left-side text-dark-color text-size-3 description-text-limit-width line-height-1-2">
  Наше предприятие занимается изготовлением изделий под индивидуальные требования.
  Мы работаем как с серийными решениями, так и с уникальными заказами, создавая оборудование
  для диспетчеризации, автоматизации и контроля инженерных систем.
</p>

<h2 class="title-bold-no-left-paddings left-side">НАПРИМЕР</h2>

<div class="block-bottom-padding left-side pr-6">
  <div class="flex flex-col gap-y-12 mobile:gap-y-6">
    <div *ngFor="let example of examples; let i = index" class="flex flex-col mobile:flex-row gap-y-2 gap-x-10 mobile:items-center">
      <div *ngIf="i % 2 == 1"
           class="flex-col hidden mobile:flex">
        <span class="text-right text-size-example oswald-medium text-blue-color line-height-1-1">{{example.name}}</span>
      </div>
      <div class="example-image-div relative">
        <svg
          class="absolute inset-0 z-0 h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <circle class="inline" cx="100" cy="100" r="98" fill="#FFFFFF"/>
        </svg>
        <img src="{{example.iconUrl}}" class="relative my-auto h-full w-full"/>
      </div>
      <div *ngIf="i % 2 == 0"
        class="flex-col hidden mobile:flex">
        <span class="text-size-example oswald-medium text-blue-color line-height-1-1">{{example.name}}</span>
      </div>
      <div class="flex-col flex mobile:hidden">
        <span class="text-size-example oswald-medium text-blue-color line-height-1-1">{{example.name}}</span>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">КАК ОФОРМИТЬ ЗАКАЗ</h2>

<div class="block-bottom-padding left-side pr-6">
  <div class="flex flex-col gap-y-4 mobile:gap-y-7 semi:gap-y-10">
    <div class="flex flex-col semi:flex-row gap-y-4 mobile:gap-y-7 semi:gap-y-10 semi:gap-x-16 semi-2:gap-x-28">
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-yellow-color oswald-medium bullet-digit">1</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">
          вы отправляете нам заявку, созваниваемся и обсуждаем требования проекта
        </span>
      </div>
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-yellow-color oswald-medium bullet-digit">2</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">
          мы с вами согласовываем техническое задание и уточняем детали
        </span>
      </div>
    </div>
    <div class="flex flex-col semi:flex-row gap-y-4 mobile:gap-y-7 semi:gap-y-10 semi:gap-x-16 semi-2:gap-x-28">
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-yellow-color oswald-medium bullet-digit">3</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">
          мы проектируем, разрабатываем, производим и тестируем изделие
        </span>
      </div>
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-yellow-color oswald-medium bullet-digit">4</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">
          мы поставляем вам изделие, настраиваем его, обучаем работе с ним
        </span>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ОСТАВЬТЕ ЗАЯВКУ НА БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ</h2>

<div class="bottom-page-padding left-side">
  <div>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <p *ngIf="requestSent && requestSuccess"
         class="text-green-500 oswald-medium text-size-4 line-height-1-1"
      >Спасибо за заявку!<br/>Скоро свяжемся с вами!</p>
      <p *ngIf="requestSent && !requestSuccess"
         class="text-red-400 oswald-medium text-size-4 line-height-1-1"
      >Возникла ошибка при отправке сообщения =(<br/>Проверьте, пожалуйста, интернет-соединение</p>

      <div *ngIf="!requestSent || !requestSuccess">
        <span class="mb-2 text-size-4 block text-dark-color arsenal-bold">Вас интересует...</span>

          <div class="grid grid-cols-2 gap-x-0 gap-y-1 xs:gap-y-3 sm:grid-cols-4 subjects-block">
            <div
              *ngFor="let option of subjectsOfInterest; let i = index"
              (click)="selectSubjectOfInterest(i)"
              [class]="
              'flex flex-col items-center justify-start rounded-xl py-2 px-2 cursor-pointer transition-all ' +
              (selectedSubjectOfInterestIndex === i ? 'border-2 border-yellow-400 bg-yellow-100' : 'border-gray-300 hover:border-gray-400')
            "
            >
              <img src="{{option.iconUrl}}" class="form-choice-icon"/>
              <span class="mt-2 text-size-1 text-center text-dark-color less-line-height" [innerHTML]="option.name"></span>
            </div>
          </div>

          <div
            *ngIf="form.controls.subjectOfInterest.invalid && showAllErrors"
            class="text-red-500 text-size-1 mt-1"
          >
            Выберите один из вариантов
          </div>

        <span class="mt-5 mb-2 text-size-4 block text-dark-color arsenal-bold">Вы интересуетесь как...</span>

          <div class="grid grid-cols-2 gap-x-0 gap-y-1 xs:gap-y-3 sm:grid-cols-4 professions-block">
            <div
              *ngFor="let option of professions; let i = index"
              (click)="selectProfession(i)"
              [class]="
              'flex flex-col items-center justify-start rounded-xl py-2 px-2 cursor-pointer transition-all ' +
              (selectedProfessionIndex === i ? 'border-2 border-yellow-400 bg-yellow-100' : 'border-gray-300 hover:border-gray-400')
            "
            >
              <img src="{{option.iconUrl}}" class="form-choice-icon"/>
              <span class="mt-2 text-size-1 text-center text-dark-color less-line-height" [innerHTML]="option.name"></span>
            </div>
          </div>

          <div
            *ngIf="form.controls.profession.invalid && showAllErrors"
            class="text-red-500 text-size-1 mt-1"
          >
            Выберите один из вариантов
          </div>

          <div>
            <div class="mt-4 pt-0">
              <span class="text-size-3 text-dark-color arsenal-bold">Имя</span>

              <div class="inline-block ml-0 sm:ml-2"
              >
                <input
                  type="text"
                  [formControl]="form.controls.name"
                  placeholder="как к вам обращаться?"
                  class="border rounded-1 w-auto normal-paddings mt-1 ml-3 input-text-size rounded"
                >
              </div>

              <div
                *ngIf="form.controls.name.invalid && (form.controls.name.touched || showAllErrors)"
                class="text-red-500 text-size-1 mt-1"
              >
                Введите своё имя
              </div>
            </div>

            <div class="mt-2 pt-0">
              <span class="text-size-3 text-dark-color arsenal-bold">Телефон</span>

              <div class="inline-block ml-0 sm:ml-2"
              >
                <input
                  type="text"
                  [formControl]="form.controls.phone"
                  placeholder="+375XXXXXXXXX"
                  class="border rounded-1 w-auto normal-paddings mt-1 ml-3 input-text-size rounded"
                  (input)="formatPhoneNumber($event)"
                >
              </div>

              <div
                *ngIf="form.controls.phone.invalid && (form.controls.phone.touched || showAllErrors)"
                class="text-red-500 text-size-1 mt-1"
              >
                Введите номер телефона
              </div>
            </div>
          </div>

        <input type="hidden" [formControl]="form.controls.empty"/>

          <button type="submit" class="mt-4 button-blue-hover-yellow text-size-3"
          >Отправить заявку</button>
      </div>
    </form>
  </div>
</div>

<app-footer></app-footer>
