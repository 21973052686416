<app-header></app-header>

<div class="padding-top-gallery block-bottom-padding"
  *ngIf="images">
  <app-image-carousel
    [images]="images"
  ></app-image-carousel>
</div>

<h1 class="title-bold-no-left-paddings pt-0 left-side">ГАЛЕРЕЯ</h1>

<div class="bottom-page-padding left-side">
  <div class="grid grid-cols-1 semi:grid-cols-2 gap-y-12 gap-x-12 grid-images-in-folder">
    <div *ngFor="let folder of galleryFolders" class="flex flex-col">
      <p class="text-size-6 text-blue-color oswald line-height-1-0 mb-4">{{folder.name}}</p>
      <div class="grid grid-cols-2 sm:grid-cols-3 semi:grid-cols-2 xl:grid-cols-3 gap-x-3 gap-y-2 grid-images-in-folder">
        <div *ngFor="let image of folder.images">
          <a class="inline-block" href="{{image}}">
            <img src="{{image}}" class="gallery-folder-image"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
