<div class="pt-3 mr-4">
  <app-field-name
    [fieldName]="fieldName"
    [required]="isFieldRequired()"
  ></app-field-name>

  <div *ngIf="nextLine" class="block"
  >
    <input type="date"
           class="custom-form-control normal-paddings"
           [formControl]="formElement"
           [attr.max]="maxDate">
  </div>
  <div *ngIf="!nextLine" class="inline-block ml-2"
  >
    <input type="date"
           class="custom-form-control normal-paddings"
           [formControl]="formElement"
           [attr.max]="maxDate">
  </div>

  <app-field-validator
    [formElement]="formElement"
    [showError]="showError"
  ></app-field-validator>

</div>
