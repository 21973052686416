import {AfterViewInit, Component, ElementRef, HostListener, QueryList, ViewChildren} from '@angular/core';
import {Instruction} from "@app/entity/Instruction";

@Component({
  selector: 'app-calibration',
  templateUrl: './calibration.component.html',
  styleUrls: ['./calibration.component.css']
})
export class CalibrationComponent implements AfterViewInit {
  instructions: Instruction[] = [
    {name: 'Минск',
      steps: [
        'согласуем дату и время приёмки по телефону +375 44 710-78-65',
        'скачиваете и заполняете шаблон заявки',
        'распечатываете и вкладываете заявку к манометрам',
        'привозите манометры с заявкой по адресу г. Минск, ул. Заславская 12, оф. 356а',
        'диагностируем манометры, оформляем договор и счёт-протокол, высылаем их сканы на ваш e-mail',
        'поверяем и при необходимости ремонтируем манометры',
        'звоним, когда всё готово, чтобы вы приехали и забрали манометры, а также распечатанные экземпляры договора, акта и счёт-протокола']},
    {name: 'Беларусь',
      steps: [
        'скачиваете и заполняете шаблон заявки',
        'упаковываете манометры в коробку, обернув их в пузырчатую плёнку, а если слоёв много, то прокладываете между ними гофрированный картон',
        'распечатываете и вкладываете заявку в коробку к манометрам',
        'отправляете манометры по почте с пометкой «осторожно, хрупкое» на адрес г. Минск, ул. Заславская 12, оф. 356а, или через Autolight Express на пункт выдачи №70108, г. Минск, пр. Машерова, 76А-2',
        'диагностируем манометры, оформляем договор и счёт-протокол, высылаем их сканы на ваш e-mail',
        'поверяем и при необходимости ремонтируем манометры',
        'высылаем поверенные и отремонтированные манометры на ваш почтовый адрес с распечатанными экземплярами договора, акта и счёт-протокола']}
  ];

  openedInstruction: Instruction = null;
  contentHeights: { [key: string]: number } = {};
  @ViewChildren('contentRef') contentBlocks!: QueryList<ElementRef>;

  ngAfterViewInit() {
    this.updateHeights();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateHeights();
  }

  updateHeights() {
    this.contentBlocks.forEach((block, index) => {
      const key = this.instructions[index].name;
      this.contentHeights[key] = block.nativeElement.scrollHeight;
    });
  }

  toggle(instruction: any) {
    this.openedInstruction = this.openedInstruction === instruction ? null : instruction;
  }
}
