import { Injectable } from '@angular/core';
import {Article} from "@app/entity/Article";
import {ArticleParagraph} from "@app/entity/ArticleParagraph";
import {Bullet} from "@app/entity/Bullet";
import {BulletItem} from "@app/entity/BulletItem";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private articles: Article[] = [
    this.getDispatchingArticle(),
    this.getRs485Article(),
    this.getTcpArticle(),
    this.getModbusArticle()
  ]

  getArticles() {
    return this.articles;
  }

  getArticleById(id: number): Article {
    return this.articles.find(article => article.id === id);
  }

  searchArticles(term: string): Article[] {
    if (!term.trim()) {
      return this.articles;
    }

    term = term.toLowerCase();

    return this.articles.filter(article =>
      article.title.toLowerCase().includes(term) ||
      article.description.toLowerCase().includes(term)
    );
  }

  private getDispatchingArticle(): Article {
    const article = new Article();
    article.id = 1;
    article.title = 'Цель диспетчеризации';
    article.description = 'увеличение эффективности использования энергоресурсов';
    article.iconUrl = 'assets/images/goal_dispatching.png';
    article.topImages = ['assets/images/disp_article_1.jpg',
                         'assets/images/disp_article_2.jpg',
                         'assets/images/disp_article_3.jpg'];
    article.introduction = 'Диспетчеризация — это процесс централизованного контроля и управления инженерными системами ' +
      'объектов, таких как жилые дома, административные здания, промышленные предприятия и коммунальные объекты. ' +
      'Она позволяет дистанционно мониторить и регулировать работу различных систем, включая отопление, водоснабжение, ' +
      'электроснабжение, вентиляцию и другие.';
    article.paragraphs = [
      new ArticleParagraph(null, null, 'в цели диспетчеризации входит',
        new Bullet([
          new BulletItem('повышение эффективности использования ресурсов'),
          new BulletItem('снижение эксплуатационных затрат'),
          new BulletItem('улучшение качества обслуживания')
        ])),
      new ArticleParagraph('В первую очередь диспетчеризация позволяет оптимизировать ' +
        'потребление энергии и воды, снижая излишние расходы и потери. Например, это может быть автоматический съём показаний ' +
        'с квартирных счётчиков, а также с датчиков освещения в подъезде.'),
      new ArticleParagraph('Централизованный мониторинг позволяет оперативно ' +
        'выявлять и устранять неисправности, обеспечивая бесперебойную работу систем и позволяя оперативно реагировать на ' +
        'аварийные ситуации (неисправность оборудования, протечка, несанкционированный доступ, резкое изменение температуры).'),
      new ArticleParagraph('Автоматизация процессов позволяет также снизить влияние человеческого фактора - сократить ' +
        'потребность в ручном труде, уменьшить количество ошибок и повысить общую эффективность работы персонала. ' +
        'В результате уменьшаются затраты на рабочее время сотрудников.'),
      new ArticleParagraph('При интеграции автоматизированных систем целой сети объектов в ' +
        'единое решение необходим централизованный диспетчерский пункт для сбора данных, управления и ' +
        'оперативного контроля аварийных ситуаций. В этом случае система диспетчеризации не выступает просто в роли ' +
        'дополнения, а становится неотъемлемой составляющей всего комплекса автоматизации.',
        'assets/images/dispatching-scheme-article.png',
        'диспетчерский пункт'),
      new ArticleParagraph('Систему диспетчеризации можно условно разделить на три уровня:', null,
        '3 уровня системы диспетчеризации',
        new Bullet([
          new BulletItem('аппаратный уровень',
            'это измерительные приборы, датчики и контроллеры, выбирающие, какие устройства' +
            ' и протоколы будут использоваться для передачи данных'),
          new BulletItem('коммуникационный уровень',
            'здесь определяются технологии и средства для основных и резервных каналов связи, ' +
            'а также устанавливаются шлюзы для согласования протоколов и интерфейсов'),
          new BulletItem('управляющий уровень',
            'на этом уровне подбирается программное обеспечение (обычно SCADA-системы), ' +
            'отвечающее за сбор, анализ и интеграцию данных с другими подсистемами; такой софт должен быть гибко ' +
            'настраиваемым, чтобы работать с оборудованием разных производителей')
        ]))
    ];
    article.conclusion = 'Внедрение системы диспетчеризации требует тщательной подготовки и учета специфики объекта. ' +
      'Необходимо провести анализ существующих инженерных систем, определить требования к оборудованию и ' +
      'программному обеспечению, а также обеспечить обучение персонала. Однако, несмотря на начальные затраты, ' +
      'долгосрочные преимущества, такие как снижение эксплуатационных расходов и повышение эффективности работы, ' +
      'делают внедрение диспетчеризации выгодным и целесообразным.';

    return article;
  }

  private getRs485Article(): Article {
    const article = new Article();
    article.id = 2;
    article.title = 'Интерфейс RS-485';
    article.description = 'распространённый стандарт физического уровня связи';
    article.iconUrl = 'assets/images/rs485 icon.png';
    article.topImages = ['assets/images/rs485_article_1.jpg',
                         'assets/images/rs485_article_2.jpg',
                         'assets/images/rs485_article_3.jpg'];
    article.introduction = 'Интерфейс RS-485, также известный как EIA-485, является стандартом для последовательной ' +
      'передачи данных с помощью дифференциального сигнала. Он широко применяется в промышленной автоматизации, ' +
      'системах диспетчеризации и телекоммуникациях благодаря низкой стоимости реализации и высокой устойчивости к помехам.';
    article.paragraphs = [
      new ArticleParagraph('Сеть на основе интерфейса RS-485 включает в себя приёмопередатчики, соединённые посредством ' +
        'витой пары. В её основе лежит принцип дифференциальной ' +
        'передачи данных, когда один сигнал передаётся одновременно по двум проводам.'),
      new ArticleParagraph('Один из таких проводов (обозначим его как A) передаёт исходный сигнал, когда другой ' +
        '(провод B) несёт его инверсную версию. Иначе говоря, если у первого провода фиксируется «1», ' +
        'то у второго — «0», и наоборот. Благодаря этому разность потенциалов между ними всегда ненулевая: ' +
        'при передаче «1» она положительная, а при «0» — отрицательная.',
        'assets/images/rs485-scheme-article.jpg'),
      new ArticleParagraph('Передача сигнала осуществляется именно благодаря разности потенциалов между проводами. ' +
        'Такой метод позволяет значительно повысить помехоустойчивость, особенно к синфазным помехам — помехам, ' +
        'одинаково влияющим на оба провода линии. Например, электромагнитное излучение, воздействуя на участок связи, ' +
        'индуцирует напряжение в обоих проводах. В интерфейсе RS-232, где сигнал передаётся относительно общего провода, ' +
        'такие наводки могут значительно исказить передаваемые данные. Более того, на больших участках общего провода ' +
        'возникает падение напряжения, из-за чего возникают дополнительные искажения.',
        null, 'устойчивость к помехам'),
      new ArticleParagraph('Однако при дифференциальной передаче такие искажения исключены. Когда два провода ' +
        'расположены рядом и скручены, внешние наводки воздействуют на них одинаково. Это приводит к тому, ' +
        'что напряжение в каждом проводе изменяется синхронно, но разность потенциалов между ними остаётся неизменной. ' +
        'Именно благодаря этому принципу передача информации остаётся стабильной и защищённой ' +
        'от внешних электромагнитных помех.'),
      new ArticleParagraph('Аппаратная реализация данного интерфейса основана на использовании микросхем ' +
        'приёмопередатчиков, которые оснащены дифференциальными входами и выходами для подключения к линии связи, ' +
        'а также цифровыми портами для взаимодействия с UART-контроллером. В зависимости от конфигурации ' +
        'существует два типа интерфейса: RS-422 и RS-485.',
        null, 'аппаратная часть'),
      new ArticleParagraph(null, null, null,
        new Bullet([
          new BulletItem('RS-422, полнодуплексный', 'передача и приём данных ' +
            'осуществляются по двум раздельным парам проводов, причём каждая пара предназначена для одного передатчика'),
          new BulletItem('RS-485, полудуплексный', 'одна пара проводов используется для приёма ' +
            'и передачи, причём эти процессы происходят в разные моменты времени, из-за чего в сети может быть много ' +
            'передатчиков, поскольку они могут отключаться, когда работают в режиме приёма')
        ])),
      new ArticleParagraph('Все устройства в сети RS-485 подключаются к общей витой паре одинаковым образом: ' +
        'прямые выходы (A) соединяются с одним проводом, а инверсные (B) — с другим.'),
      new ArticleParagraph('Входное сопротивление приёмника на стороне линии (RAB) обычно равно 12 кОм. ' +
        'Из-за ограниченной мощности передатчика количество приемников в сети не может быть бесконечным. ' +
        'В соответствии с RS-485, передатчик способен поддерживать связь максимум с 32 приёмниками, ' +
        'если учитывать согласующие резисторы.',
        null, 'количество приёмников'),
      new ArticleParagraph('Однако существуют микросхемы с увеличенным входным сопротивлением, ' +
        'которые позволяют подключать к сети значительно больше устройств. С применением магистральных ' +
        'усилителей число узлов может достигать 256.'),
      new ArticleParagraph('Скорость передачи данных в стандарте RS-485 может достигать 10 Мбит/с, ' +
        'а предельное расстояние соединения — 1200 м. Если требуется передача на более дальние ' +
        'расстояния или подключение большего количества устройств, чем выдерживает передатчик, ' +
        'используются специальные повторители сигнала.',
        null, 'технические характеристики опроса'),
      new ArticleParagraph('Контроллер опрашивает модули с определённой частотой, поэтому изменения ' +
        'в сети не фиксируются мгновенно. Время реакции зависит от скорости опроса, эффективности алгоритмов ' +
        'и количества подключённых устройств. При наличии большого числа модулей полный цикл опроса занимает ' +
        'некоторое время, а пока очередь не дойдёт до конкретного модуля, контроллер не сможет определить ' +
        'его новое состояние.')
    ];
    article.conclusion = 'RS-485 – это интерфейс для экономной и устойчивой последовательной передачи данных ' +
      'в условиях промышленного использования. Его особенности – дифференциальная передача, возможность организации ' +
      'многоточечной сети и высокая помехоустойчивость.';

    return article;
  }

  private getTcpArticle(): Article {
    const article = new Article();
    article.id = 3;
    article.title = 'Набор протоколов TCP/IP';
    article.description = 'сетевая архитектура для обмена данными';
    article.iconUrl = 'assets/images/tcp icon.png';
    article.topImages = ['assets/images/tcp_article_1.jpg',
                         'assets/images/tcp_article_2.jpg',
                         'assets/images/tcp_article_3.jpg'];
    article.introduction = 'Модель TCP/IP – это сетевая архитектура для обмена цифровыми данными между устройствами. ' +
      'Она описывает последовательность уровней и протоколов, необходимых для передачи информации, а основной документ, ' +
      'определяющий её уровневую структуру и перечень протоколов, – RFC 1122.';
    article.paragraphs = [
      new ArticleParagraph('В отличие от теоретически исчерпывающей, но практически громоздкой модели OSI, ' +
        'TCP/IP объединяет некоторые уровни для упрощения и оптимизации построения сетей. ' +
        'Многоуровневая модель OSI, хотя и обладает глубоким теоретическим обоснованием, требует избыточных ресурсов ' +
        'для реализации, что затрудняет её применение в реальных условиях.'),
      new ArticleParagraph('TCP/IP же разработана для решения этих задач, и её название происходит от наиболее ' +
        'часто используемых в ней протоколов. Сегодня модель TCP/IP является де-факто стандартом в области сетевых технологий.',
        'assets/images/tcp-osi-scheme.png'),
      new ArticleParagraph('На иллюстрации показано сопоставление уровней OSI и TCP/IP, где видно, ' +
        'что модель TCP/IP объединяет некоторые функции, предлагаемые отдельными уровнями OSI, ' +
        'что упрощает архитектуру сети.'),
      new ArticleParagraph('Канальный уровень отвечает за кодирование данных для формирования пакетов ' +
        'на физическом уровне. Здесь используются специальные битовые последовательности, обозначающие начало ' +
        'и конец пакета, а также механизмы, повышающие устойчивость к помехам.',
        null, 'канальный уровень'),
      new ArticleParagraph('Сетевой уровень занимается маршрутизацией пакетов между разными сетями. ' +
        'На этом этапе маршрутизаторы анализируют IP-адреса с учетом сетевых масок, чтобы корректно ' +
        'направить данные в нужное направление.',
        null, 'сетевой уровень'),
      new ArticleParagraph('Транспортный уровень обеспечивает надежную доставку данных и корректное ' +
        'их упорядочивание. В TCP/IP выделяются два основных протокола:',
        null, 'транспортный уровень',
        new Bullet([
          new BulletItem('TCP (Transmission Control Protocol)', 'протокол с установлением соединения, ' +
            'гарантирующий надёжный поток данных; он обеспечивает контроль целостности, повторную передачу утраченных данных, ' +
            'устранение дублирования и управление нагрузкой на сеть, а также сохраняет порядок передачи пакетов'),
          new BulletItem('UDP (User Datagram Protocol)', 'протокол, работающий без установления соединения, ' +
            'что делает его менее надёжным; он допускает перемешивание пакетов и не подтверждает их доставку, ' +
            'поэтому для критичных приложений, где необходима гарантия передачи, предпочтительнее TCP')
        ])),
      new ArticleParagraph('При реализации приложений часто используется понятие «порт» – числовой идентификатор ' +
        '(от 0 до 65535), который позволяет направить входящий пакет данных конкретному процессу на устройстве. ' +
        'Это дополнительно уточняет, какое приложение или служба должны обработать информацию.'),
      new ArticleParagraph('Прикладной уровень предназначен для взаимодействия сетевых приложений. ' +
        'Здесь работают протоколы, обеспечивающие обмен данными между конечными пользователями. ' +
        'Примеры включают:',
        null, 'прикладной уровень',
        new Bullet([
          new BulletItem('HTTP', 'для передачи веб-страниц (TCP-порты 80 или 8080)'),
          new BulletItem('FTP', 'для обмена файлами (TCP-порты 20 и 21)'),
          new BulletItem('SMTP', 'для отправки электронной почты'),
          new BulletItem('SSH', 'для защищённого удаленного доступа (TCP-порт 22)'),
          new BulletItem('DNS', 'для преобразования доменных имен в IP-адреса (обычно UDP-порт 53, иногда TCP)')
        ])),
      new ArticleParagraph('Эти прикладные протоколы, как правило, работают поверх TCP или UDP, ' +
        'обеспечивая необходимый уровень взаимодействия между пользователями.')
    ];
    article.conclusion = 'Модель TCP/IP полностью реализует функциональные возможности, предусмотренные моделью OSI, ' +
      'но делает это в более компактной и практичной форме. Благодаря независимости от физической среды передачи данных, ' +
      'она обеспечивает прозрачное взаимодействие между проводными и беспроводными сетями. Каждый пакет данных ' +
      'включает в себя «стек» протоколов, который формируется при отправке и разбирается в обратном порядке ' +
      'при получении.';

    return article;
  }

  private getModbusArticle(): Article {
    const article = new Article();
    article.id = 4;
    article.title = 'Протокол Modbus UART';
    article.description = 'интегрирование оборудования в единую систему';
    article.iconUrl = 'assets/images/modbus icon.png';
    article.topImages = ['assets/images/modbus_article_1.jpg',
                         'assets/images/modbus_article_2.jpg',
                         'assets/images/modbus_article_3.jpg'];
    article.introduction = 'Modbus был разработан компанией Modicon (ныне Schneider Electric) как открытый стандарт ' +
      'для промышленной автоматики. Первоначально реализованный через интерфейс RS-232, протокол впоследствии адаптировали ' +
      'для работы с RS-485 и для использования в сетях TCP/IP (Modbus TCP), что обеспечило его широкое распространение в индустрии.';
    article.paragraphs = [
      new ArticleParagraph('Благодаря своей универсальности и открытости, Modbus позволяет интегрировать оборудование ' +
        'различных производителей в единую систему управления. Он используется для сбора данных с датчиков, ' +
        'управления реле, контроллерами и мониторинга технологических процессов.'),
      new ArticleParagraph(null, null,
        'основные варианты протокола',
        new Bullet([
          new BulletItem('Modbus ASCII', 'символьная версия, удобная для ручного ввода команд в терминале; ' +
            'однако её сообщения занимают больше байт, что снижает скорость передачи данных'),
          new BulletItem('Modbus TCP', 'реализует обмен данными по Ethernet, но работает только с устройствами, ' +
            'подключёнными к TCP-сети, т.е. нельзя работать по единой шине для связи'),
          new BulletItem('Modbus RTU', 'бинарный протокол, оптимизированный для работы по последовательным ' +
            'линиям связи (RS-485); он обеспечивает компактность сообщений и возможность опроса ' +
            'множества узлов по единой шине')
        ])),
      new ArticleParagraph('Сообщение Modbus RTU состоит из следующих основных элементов:',
        'assets/images/modbus-scheme.png',
        'структура сообщения Modbus RTU',
        new Bullet([
          new BulletItem('сетевой адрес (AA)', 'задаёт адрес узла от 1 до 255; ' +
            'значение 0 используется для широковещательных команд, которые обрабатываются всеми устройствами'),
          new BulletItem('код функции (CC)', 'однобайтовый идентификатор функции, определяющий, ' +
            'какое действие требуется выполнить'),
          new BulletItem('поле данных (DD...DD)', 'содержит аргументы команды или данные ответа на неё; ' +
            'в протоколе данные организованы в виде регистров по 2 байта, причём поле данных может быть ' +
            'пустым для команд, выполняющих действия без параметров'),
          new BulletItem('контрольная сумма CRC16 (SS SS)', 'используется для проверки целостности сообщения'),
        ])),
      new ArticleParagraph('Если адрес узла совпадает с указанным в запросе, устройство обрабатывает команду ' +
        'и формирует ответ. При широковещательном запросе ответ формируется только для специальных команд ' +
        '(например, для сканирования по серийному номеру), что позволяет автоматически определять адреса устройств в сети.'),
      new ArticleParagraph('Ответ устройства может быть двух типов:',
        null, 'формат ответа на команду',
        new Bullet([
          new BulletItem('успешный ответ', 'AA CC DD...DD SS SS, где CC – копия кода функции, ' +
            'а DD...DD – данные, зависящие от команды'),
          new BulletItem('ответ с ошибкой', 'AA CC EE SS SS, где в CC устанавливается старший бит, ' +
            'а EE – код ошибки')
        ])),
      new ArticleParagraph('Коды ошибок Modbus, например, ILLEGAL FUNCTION, ILLEGAL DATA ADDRESS, ' +
        'ILLEGAL DATA VALUE, используются для диагностики сбоев, когда устройство не поддерживает команду, ' +
        'запрашивается неверный регистр или передается недопустимое значение.'),
      new ArticleParagraph('Что касается команд Modbus, то основные из них - это чтение регистров (команда 3), ' +
        'запись одного регистра (команда 6) и запись нескольких регистров (команда 16). Запросы содержат ' +
        'информацию о номере и количестве регистров, а ответы возвращают данные или подтверждение ' +
        'успешного выполнения операции.')
    ];
    article.conclusion = 'Modbus RTU является оптимальным решением для систем, требующих компактной и быстрой ' +
      'передачи данных по единой шине. Его структура позволяет эффективно адресовать и управлять множеством узлов, ' +
      'а открытый стандарт обеспечивает совместимость оборудования различных производителей.';

    return article;
  }
}
