import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-offer-block',
  templateUrl: './offer-block.component.html',
  styleUrls: ['./offer-block.component.css']
})
export class OfferBlockComponent {
  @Input() indexesToShow: number[] = [0, 1, 2, 3, 4, 5, 6, 7];

  offers = [
    { text: 'снятие показаний в режиме<br/>реального времени', icon: 'assets/images/realtime.png' },
    { text: 'удалённый контроль<br/>и управление', icon: 'assets/images/controller.png' },
    { text: 'оперативное реагирование<br/>на аварийные ситуации', icon: 'assets/images/siren.png' },
    { text: 'ведение архива<br/>состояний параметров', icon: 'assets/images/archive.png' },
    { text: 'снижение влияния<br/>человеческого фактора', icon: 'assets/images/shield.png' },
    { text: 'уменьшение численности<br/>персонала', icon: 'assets/images/economy.png' },
    { text: 'адаптация системы<br/>под разные условия', icon: 'assets/images/puzzle.png' },
    { text: 'техническое<br/>обслуживание', icon: 'assets/images/maintenance.png' }
  ];
}
