<div class="pt-0 mr-4">
  <app-field-name
    [fieldName]="fieldName"
    [required]="isFieldRequired()"
  ></app-field-name>

  <div *ngIf="nextLine" class="block"
  >
    <input
      type="text"
      class="border rounded-1 w-full normal-paddings mt-1 input-text-size"
      [formControl]="formElement"
    >
  </div>
  <div *ngIf="!nextLine" class="inline-block ml-2"
  >
    <input
      type="text"
      class="border rounded-1 w-auto normal-paddings mt-1 ml-3 input-text-size"
      [formControl]="formElement"
    >
  </div>

  <app-field-validator
    [formElement]="formElement"
    [showError]="showError"
    [additionalNote]="additionalNote"
  ></app-field-validator>

</div>
