<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ДИСПЕТЧЕРИЗАЦИЯ</h1>

<ul class="block-bottom-padding inline-block pl-0 left-side">
  <li
    *ngFor="let solution of solutions"
    [routerLink]="'/' + solution.routerLink"
    class="group flex items-center space-x-2 py-2 pl-4 pr-4 relative cursor-pointer line-height-1-2"
    (mouseenter)="setHoveredSolution(solution)"
    (mouseleave)="setHoveredSolution(null)">
    <div class="transition-all duration-300"
      [ngStyle]="{ 'margin-left': hoveredSolution === solution ? '15px' : '5px' }">
      <div
        class="bg-blue-color transition-all duration-300 h-full absolute left-0 top-0 bottom-0"
        [ngStyle]="{ width: hoveredSolution === solution ? '15px' : '3px' }">
      </div>
      <span class="text-size-4 oswald">
        {{ solution.name }}
      </span>
    </div>
  </li>
</ul>

<div class="block-bottom-padding pt-4 left-side">
  <div class="flex flex-col semi-2:flex-row items-start gap-y-12 semi-2:gap-x-24 semi-2:gap-y-0">
    <div class="sign-block inline-flex flex-col items-center">
      <h2 class="oswald text-dark-color text-size-small line-height-1-2 inline-block">ПРОГРАММНО-ТЕХНИЧЕСКИЙ</h2>
      <h2 class="oswald text-dark-color text-size-medium line-height-0-9 inline-block">КОМПЛЕКС</h2>
      <h2 class="oswald-medium text-yellow-color text-size-big line-height-0-9 inline-block">ДЕЛЬТА</h2>
    </div>

    <div class="flex flex-col semi-2:items-center gap-y-6 pr-6">
      <p class="text-dark-color oswald text-size-4 line-height-0-9">включает в себя диспетчеризацию процессов</p>
      <div class="flex flex-col sm:flex-row items-start gap-y-10 sm:gap-x-8 sm:gap-y-4">
        <div class="flex flex-row gap-x-12 sm:gap-x-8">
          <div class="flex flex-col items-center gap-y-3">
            <img src="assets/images/radiator%20(1).png" class="process-icon"/>
            <span class="text-dark-color text-size-2 text-center line-height-1-1 inline-block">тепло-<br/>снабжения</span>
          </div>
          <div class="flex flex-col items-center gap-y-3">
            <img src="assets/images/plug%20(1).png" class="process-icon"/>
            <span class="text-dark-color text-size-2 text-center line-height-1-1 inline-block">электро-<br/>снабжения</span>
          </div>
        </div>
        <div class="flex flex-row gap-x-12 sm:gap-x-8">
          <div class="flex flex-col items-center gap-y-3">
            <img src="assets/images/water-tap%20(1).png" class="process-icon"/>
            <span class="text-dark-color text-size-2 text-center line-height-1-1 inline-block">водо-<br/>снабжения</span>
          </div>
          <div class="flex flex-col items-center gap-y-3">
            <img src="assets/images/sewage%20(1).png" class="process-icon"/>
            <span class="text-dark-color text-size-2 text-center line-height-1-1 inline-block">водо-<br/>отведения</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ЧТО МЫ ПРЕДЛАГАЕМ</h2>

<div class="block-bottom-padding left-side">
  <app-offer-block></app-offer-block>
</div>

<h2 class="title-bold-no-left-paddings left-side">КАК РАБОТАЕТ СИСТЕМА</h2>

<div class="block-bottom-padding left-side">
  <p class="description-text-limit-width text-size-3 text-dark-color line-height-1-2 mt-0">
    Центральное место в системе занимает сервер, подключенный к сети передачи данных, или «облако».
    Сервер связан с контроллерами «Дельта-5», устанавливаемыми на объектах контроля (тепловых узлах, электрощитовых и др.),
    либо каналами Ethernet, либо двухпроводными линиями связи по интерфейсу RS-485.
  </p>
  <p class="description-text-limit-width text-size-3 text-dark-color line-height-1-2 mt-0">
    К базам данных сервера по Ethernet-каналам через свои устройства могут иметь доступ клиенты
    (диспетчера, руководители, контролирующие органы и др.). Режимы доступа (наблюдение, управление,
    конфигурирование и др.) разграничиваются системой паролей.
  </p>
  <p class="description-text-limit-width text-size-3 text-dark-color line-height-1-2 mt-0">
    К контроллерам «Дельта-5» подключены приборы учёта и регулирования расхода энергии и ресурсов, а также
    приборы для измерения параметров и датчики состояний, которыми оснащены объекты контроля.
    Контроллеры путем периодического опроса приборов и датчиков формируют блоки текущих состояний
    объектов контроля. Эти данные передаются на сервер.
  </p>
  <p class="description-text-limit-width text-size-3 text-dark-color line-height-1-2 mt-0">
    Система соответствует требованиям ТКП 45-4.04-327-2018 «Системы связи и диспетчеризации инженерного
    оборудования жилых и общественных зданий. Строительные нормы проектирования»
  </p>
  <a class="inline-block" href="assets/images/bagora_delta_scheme.jpg">
    <img src="assets/images/delta_scheme.png" class="scheme-image"/>
  </a>
</div>

<h2 class="title-bold-no-left-paddings left-side">ПРАКТИЧЕСКИЕ ЗАДАЧИ</h2>

<div class="bottom-page-padding left-side pr-6">
  <div class="flex flex-col gap-y-4 mobile:gap-y-7 semi:gap-y-10">
    <div class="flex flex-col semi:flex-row gap-y-4 mobile:gap-y-7 semi:gap-y-10 semi:gap-x-16 semi-2:gap-x-28">
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-blue-color oswald-medium bullet-digit">1</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">учёт расхода воды, тепловой и электрической энергии, прочих ресурсов</span>
      </div>
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-blue-color oswald-medium bullet-digit">2</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">контроль систем для своевременного регулирования, ремонта или замены</span>
      </div>
    </div>
    <div class="flex flex-col semi:flex-row gap-y-4 mobile:gap-y-7 semi:gap-y-10 semi:gap-x-16 semi-2:gap-x-28">
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-blue-color oswald-medium bullet-digit">3</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">регулирование параметров и управление оборудованием</span>
      </div>
      <div class="flex flex-row items-center gap-x-4 mobile:gap-x-6">
        <span class="text-blue-color oswald-medium bullet-digit">4</span>
        <span class="text-dark-color text-size-3 inline-block line-height-1-4 bullet-text">аварийная и охранная сигнализация</span>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
