<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">СТАТЬИ</h1>

<div class="block-bottom-padding left-side pr-6">
  <input
    type="text"
    [(ngModel)]="searchTerm"
    (input)="onSearch()"
    placeholder="поиск по статьям..."
    class="text-size-4 arsenal font-bold border px-3 py-2 w-full sm:w-[400px] rounded-lg placeholder:font-normal placeholder:text-gray-400"
  />
</div>

<div class="bottom-page-padding left-side pr-4">
  <div class="grid grid-cols-1 semi-2:grid-cols-2 gap-y-10 gap-x-14 xl:gap-x-20 grid-articles">
    <div *ngFor="let article of articles"
         class="gradient-animation article-block cursor-pointer"
         (click)="openArticle(article)">
      <div class="flex flex-row items-center gap-x-3">
        <img src="{{article.iconUrl}}" class="icon-article"/>
        <div class="flex flex-col gap-y-2">
          <span class="text-size-5 text-blue-color oswald-medium line-height-1-0">{{article.title}}</span>
          <span class="text-size-3 text-cool-gray-color oswald line-height-1-1">{{article.description}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
