import {Component, OnInit} from '@angular/core';
import {Article} from "@app/entity/Article";
import {Router} from "@angular/router";
import {ArticleService} from "@app/service/article.service";

@Component({
  selector: 'app-articles-page',
  templateUrl: './articles-page.component.html',
  styleUrls: ['./articles-page.component.css']
})
export class ArticlesPageComponent implements OnInit {
  articles: Article[] = [];
  searchTerm: string = '';

  constructor(private router: Router,
              private articleService: ArticleService) {
  }

  ngOnInit() {
    this.articles = this.articleService.getArticles();
  }

  openArticle(article: Article): void {
    this.router.navigate(['/articles', article.id]);
  }

  onSearch() {
    this.articles = this.articleService.searchArticles(this.searchTerm);
  }
}
