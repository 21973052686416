import {Component, Input} from '@angular/core';
import {ModalCreateWindowService} from "@app/service/modal-create-window.service";

@Component({
  selector: 'app-modal-window-create',
  templateUrl: './modal-window-create.component.html',
  styleUrls: ['./modal-window-create.component.css']
})
export class ModalWindowCreateComponent {
  @Input() modalTitle: string;
  @Input() topMargin = 0;
  @Input() addMarginIfDesktop = 0;

  modalService: ModalCreateWindowService;

  constructor(modalService: ModalCreateWindowService) {
    this.modalService = modalService;
  }

  calcMargin(): number {
    let margin = this.topMargin;
    if (window.innerWidth > 440) {
      margin += this.addMarginIfDesktop;
    }
    return margin;
  }
}
