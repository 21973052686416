import {Component, OnInit} from '@angular/core';
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {Characteristic} from "@app/entity/Characteristic";

@Component({
  selector: 'app-detector-flood-page',
  templateUrl: './detector-flood-page.component.html',
  styleUrls: ['../../own-block-styles.css', './detector-flood-page.component.css']
})
export class DetectorFloodPageComponent implements OnInit {
  private documentationService: DocumentationService;
  detectorFlood: DeviceDocumentation;

  characteristics: Characteristic[] = [
    {bigText: '5-30 В', middleText: 'постоянный ток', smallText: 'питающее напряжение'},
    {bigText: '12 мА', middleText: 'потребляемый ток', smallText: 'от внешнего источника'},
    {bigText: '30 В', middleText: 'коммутируемое напряжение', smallText: 'максимум'},
    {bigText: '50 мА', middleText: 'коммутируемый ток', smallText: 'максимум'},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '100х17х12 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP55', middleText: 'степень защиты', smallText: ''},
    {bigText: '30 г', middleText: 'масса', smallText: ''}
  ];

  impulseSignals: string[] = [
    'замыкание и размыкание «сухих» электрических контактов',
    'открытые коллектор-эмиттер или исток-сток транзисторов',
    'импульсы положительной полярности амплитудой от\u00A03\u00A0до\u00A024\u00A0В'
  ]

  constructionProperties: string[] = [
    'активный одноплатный модуль, помещённый в прозрачную термоусаживаемую трубку',
    'с целью защиты конструкция помещается в пластмассовую трубку с кабельными вводами',
    'линии защищены от неправильной полярности и перегрузки от\u00A0-30\u00A0В\u00A0до\u00A030\u00A0В'
  ];

  advices: string[] = [
    'при монтаже прикрепите датчик к стене с помощью двух клипс, входящих в комплект поставки',
    'поместите сенсорные электроды в зону контроля и закрепите их (клеем, дюбель-хомутами, кабель-каналом и т.д.)',
    'очищайте электроды от пыли и осадков не реже одного раза в полгода'
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.detectorFlood = this.documentationService.getDetectorFloodDocumentation();
  }
}
