import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainPageComponent} from "./pages/main-page/main-page.component";
import {ContactPageComponent} from "@app/pages/contact-page/contact-page.component";
import {DevicesPageComponent} from "@app/pages/devices-page/devices-page.component";
import {ManometerPageComponent} from "@app/pages/devices/manometer-page/manometer-page.component";
import {ControllerPageComponent} from "@app/pages/devices/controller-page/controller-page.component";
import {DetectorFloodPageComponent} from "@app/pages/devices/detector-flood-page/detector-flood-page.component";
import {EthernetPageComponent} from "@app/pages/devices/ethernet-page/ethernet-page.component";
import {
  DetectorTemperaturePageComponent
} from "@app/pages/devices/detector-temperature-page/detector-temperature-page.component";
import {
  DetectorTemperatureIndicatingPageComponent
} from "@app/pages/devices/detector-temperature-indicating-page/detector-temperature-indicating-page.component";
import {CounterImpulsePageComponent} from "@app/pages/devices/counter-impulse-page/counter-impulse-page.component";
import {DocumentationPageComponent} from "@app/pages/documentation-page/documentation-page.component";
import {ClientsPageComponent} from "@app/pages/clients-page/clients-page.component";
import {DispatchingPageComponent} from "@app/pages/dispatching-page/dispatching-page.component";
import {DeferrizationComponent} from "@app/pages/solutions/deferrization/deferrization.component";
import {HeatingComponent} from "@app/pages/solutions/heating/heating.component";
import {HousesComponent} from "@app/pages/solutions/houses/houses.component";
import {SewerageComponent} from "@app/pages/solutions/sewerage/sewerage.component";
import {CalibrationComponent} from "@app/pages/calibration/calibration.component";
import {CustomizationPageComponent} from "@app/pages/customization-page/customization-page.component";
import {ProgramsPageComponent} from "@app/pages/programs-page/programs-page.component";
import {HistoryPageComponent} from "@app/pages/history-page/history-page.component";
import {GalleryPageComponent} from "@app/pages/gallery-page/gallery-page.component";
import {VideoPageComponent} from "@app/pages/video-page/video-page.component";
import {ArticlesPageComponent} from "@app/pages/articles-page/articles-page.component";
import {SingleArticlePageComponent} from "@app/pages/articles/single-article-page/single-article-page.component";

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent
  },
  {
    path: 'contact',
    component: ContactPageComponent
  },
  {
    path: 'devices',
    component: DevicesPageComponent
  },
  {
    path: 'manometer',
    component: ManometerPageComponent
  },
  {
    path: 'controller',
    component: ControllerPageComponent
  },
  {
    path: 'detector-flood',
    component: DetectorFloodPageComponent
  },
  {
    path: 'ethernet',
    component: EthernetPageComponent
  },
  {
    path: 'detector-temperature',
    component: DetectorTemperaturePageComponent
  },
  {
    path: 'detector-temperature-indicating',
    component: DetectorTemperatureIndicatingPageComponent
  },
  {
    path: 'counter-impulse',
    component: CounterImpulsePageComponent
  },
  {
    path: 'documentation',
    component: DocumentationPageComponent
  },
  {
    path: 'clients',
    component: ClientsPageComponent
  },
  {
    path: 'dispatching',
    component: DispatchingPageComponent
  },
  {
    path: 'deferrization',
    component: DeferrizationComponent
  },
  {
    path: 'heating',
    component: HeatingComponent
  },
  {
    path: 'houses',
    component: HousesComponent
  },
  {
    path: 'sewerage',
    component: SewerageComponent
  },
  {
    path: 'calibration',
    component: CalibrationComponent
  },
  {
    path: 'customization',
    component: CustomizationPageComponent
  },
  {
    path: 'programs',
    component: ProgramsPageComponent
  },
/*  {
    path: 'history',
    component: HistoryPageComponent
  },*/
  {
    path: 'gallery',
    component: GalleryPageComponent
  },
  {
    path: 'instructions',
    component: VideoPageComponent
  },
  {
    path: 'articles',
    component: ArticlesPageComponent
  },
  {
    path: 'articles/:id',
    component: SingleArticlePageComponent
  },
  { path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
