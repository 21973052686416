import { Component } from '@angular/core';
import {Instruction} from "@app/entity/Instruction";
import {Equipment} from "@app/entity/Equipment";

@Component({
  selector: 'app-houses',
  templateUrl: './houses.component.html',
  styleUrls: ['../../own-block-styles.css', './houses.component.css']
})
export class HousesComponent {
  private imageFolder = 'assets/images/';
  private deviceFolder = this.imageFolder + 'devices/';

  parameterSets: Instruction[] = [
    {name: 'телеизмерение', steps: [
        'давление воды на выходе артезианской скважины или на входе станции обезжелезивания',
        'давление воды потребителю',
        'объём поднятой воды',
        'объём воды на промывку фильтров',
        'температура в помещении',
        'потреблённая электрическая энергия (общая, на подъем воды, на обогрев помещений)'
      ]
    },
    {name: 'телесигнализация', steps: [
        'открытие дверей в помещении',
        'исчезновение напряжения питания ~220 В',
        'состояние двигателя насоса (вкл/выкл, потребляемый ток)',
        'сигнализация затопления'
      ]
    },
    {name: 'телеуправление', steps: [
        'включить или выключить насос',
        'внеплановая промывка'
      ]
    },
  ];

  equipments: Equipment[] = [
    {name: 'контроллер «Дельта\u20115» с\u00A0ПО', imagePath: this.deviceFolder + 'controller.png', amountStr: '1 шт'},
    {name: 'GSM\u2011модем IRZ ATM31\u2011485', imagePath: this.imageFolder + 'modem-equipment.png', amountStr: '1 шт'},
    {name: 'блок питания с\u00A0аккумулятором', imagePath: this.imageFolder + 'block-power.png', amountStr: '1 шт'},
    {name: 'счётчик импульсов автономный\u00A0СИА\u201124М', imagePath: this.deviceFolder + 'sia.png', amountStr: 'кол-во квартир +\u00A01\u00A0шт'},
    {name: 'кабели, провода, кабель-каналы, расходные материалы', imagePath: this.imageFolder + 'montage-materials.png', amountStr: 'комплект'}
  ]
}
