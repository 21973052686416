<app-header></app-header>

<h1 class="oswald-medium text-size-8 text-dark-color left-side pr-3 top-page-start-margin">{{article.title | uppercase}}</h1>
<h2 class="oswald text-size-5 text-cool-gray-color line-height-1-1 left-side pr-3">{{article.description}}</h2>

<div class="bottom-page-padding left-side pr-6 margin-top-after-article-part flex flex-col gap-y-4 mobile:gap-y-6">
  <p class="plain-text line-height-1-3 text-size-3 no-margins">{{article.introduction}}</p>

  <div class="flex flex-col gap-y-4 mobile:gap-y-6">
    <div class="grid-top-images grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-3">
      <div *ngFor="let topImage of article.topImages; let i = index"
           class="flex flex-col items-center
             {{ (i === 0 && article.topImages.length % 2 === 1) ? 'col-span-1 md:col-span-2 lg:col-span-1' : '' }}">
        <a class="inline-block" href="{{topImage}}">
          <img class="article-top-image" src="{{topImage}}"/>
        </a>
      </div>
    </div>

    <div *ngFor="let paragraph of article.paragraphs"
         class="flex flex-col gap-y-4 mobile:gap-y-6">
      <p *ngIf="paragraph.header"
        class="text-size-6 text-dark-color oswald-medium line-height-1-3 mt-3 mobile:mt-5 mb-0">{{paragraph.header | uppercase}}</p>
      <p *ngIf="paragraph.text"
         class="plain-text line-height-1-3 text-size-3 no-margins">{{paragraph.text}}</p>
      <a *ngIf="paragraph.image"
         class="inline-block w-fit mb-2" href="{{paragraph.image}}">
        <img class="article-inner-image" src="{{paragraph.image}}"/>
      </a>
      <div *ngIf="paragraph.bullet" class="flex flex-col gap-y-3">
        <div *ngFor="let bulletItem of paragraph.bullet.items; let i = index">
          <div class="flex flex-col gap-y-1">
            <div class="relative flex flex-row items-center gap-x-2 md:gap-x-3">
              <span class="text-blue-color oswald-medium {{bulletItem.description ? 'bullet-digit' : 'smaller-bullet-digit'}}">{{i + 1}}</span>
              <span class="text-blue-color oswald text-size-4 inline-block line-height-1-1 bullet-text">{{bulletItem.title}}</span>
            </div>
            <span class="text-cool-gray-color text-size-2 inline-block line-height-1-1 bullet-text">{{bulletItem.description}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p class="plain-text line-height-1-3 text-size-3 no-margins">{{article.conclusion}}</p>
</div>

<app-footer></app-footer>
