<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">НАША ИСТОРИЯ</h1>

<div class="bottom-page-padding left-side">
  <div class="flex flex-col pr-5 description-text-limit-width">
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0">начало</p>
    <p class="text-size-3 text-dark-color line-height-1-2 mt-2">
      История начинается в 19.. году - с увольнения Валентины с работы. Она искала работу, но никак не удавалось её найти.
      С этого всё и началось - Валентина открыла ИП. Первое время было непонятно, чем заниматься. Наконец Валентина
      со своим мужем Василием решили развернуть деятельность вокруг его хобби - радиоэлектроники.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">как это было</p>
    <p class="text-size-3 text-dark-color line-height-1-2 mt-2 mb-2">
      Собирали устройства прямо на балконе. Внучке всегда нравилось рассматривать инструменты и спрашивать у дедушки Васи:
    </p>
    <p class="text-size-3 text-dark-color line-height-1-2 mb-2">
      «А что это ты делаешь?»
    </p>
    <p class="text-size-3 text-dark-color line-height-1-2 mb-2">
      «Гусям ярмо» - с важным видом отвечал Василий.
    </p>
    <p class="text-size-3 text-dark-color line-height-1-2 mb-2">
      6-летняя Катя так и не могла понять, что же это за загадочное «гусямярмо», над которым дедушка постоянно работает,
      пока она сама не стала инженером-программистом и не пришла в команду «Багоры». Так назвали компанию при реорганизации
      ИП в ЧПУП в 2008 году.
    </p>
    <p class="text-size-3 text-dark-color line-height-1-2">
      Поначалу «Багора» была дополнительной работой в довесок к основным, но спустя время благодаря сарафанному
      радио появилось достаточно заказов, чтобы эта деятельность вышла на первый план.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">про основателей</p>
    <p class="text-size-3 text-dark-color line-height-1-2 mt-2">
      Валентина и Василий закончили БГУИР в 19..-х годах. Василий - по направлению, конечно же, радиоэлектроники.
      Валентина - ... . Василий .. лет сам преподавал (конечно же, радиоэлектронику). И даже был деканом (вроде бы),
      правда, спустя .. лет решил, что это не его. В отличие от радиоэлектроники, конечно.
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">как ведётся работа</p>
    <p class="text-size-3 text-dark-color line-height-1-2 mt-2 mb-2">
      Василий сам придумывает и постоянно дорабатывает конструкцию устройств. Он буквально может собрать
      работающий датчик из палочки и ниточки. Всё потому, что он понимает физику процесса, и не забывает объяснить
      её каждому подвернувшемуся слушателю.
    </p>
    <p class="text-size-3 text-dark-color line-height-1-2 mb-2">
      Для создания устройств комплектующие подбираются таким образом, чтобы себестоимость была небольшой,
      но при этом конструкция - надёжной. Как? За счёт понимания, какие дорогостоящие элементы не привносят
      функциональной пользы или какие есть альтернативы.
    </p>
    <p class="text-size-3 text-dark-color line-height-1-2">
      Другим инженерам на предприятии Василий передаёт свой многолетний опыт.
      А программной частью занимаются инженеры-программисты «Багоры».
    </p>
    <p class="text-size-6 text-blue-color oswald mb-0 line-height-1-0 mt-2">настоящее время</p>
    <p class="text-size-3 text-dark-color line-height-1-2 mt-2 mb-0">
      На момент 2025 года «Багора» - это семейное предприятие с 7 сотрудниками в штате. Кто знает, возможно,
      в будущем наше предприятие будет расширяться и выйдет за рамки концепции чисто семейного предприятия?
    </p>
  </div>
</div>

<app-footer></app-footer>
