import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Video } from '@app/entity/Video';

@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.css']
})
export class VideoPageComponent {
  videos: Video[] = [
  ];

  constructor(private sanitizer: DomSanitizer) {
    this.videos.forEach(video => {
      video.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(video.url);
    });
  }
}
