<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ВИДЕОИНСТРУКЦИИ</h1>

<div class="bottom-page-padding left-side pr-4">
  <div class="grid grid-cols-1 xl:grid-cols-2 gap-y-16 gap-x-20 grid-videos">
    <div *ngFor="let video of videos" class="instruction-block flex flex-col">
      <p class="text-size-6 text-blue-color oswald-medium line-height-1-0 mb-3">{{video.title}}</p>
      <p class="text-size-4 text-cool-gray-color oswald line-height-1-1 mb-4">{{video.description}}</p>
      <iframe
        class="video-instruction border-0"
        [src]="video.safeUrl"
        title="{{video.title}}"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>

<app-footer></app-footer>
