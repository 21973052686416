<footer class="bg-dark-color text-gray-300 py-10">
  <div class="container mx-auto footer-grid">
    <div class="flex flex-col items-start space-y-5">
      <img src="assets/images/logo_white_horizontal.png" alt="БАГОРА" class="w-40 cursor-pointer ml-1" routerLink="/"/>
      <div class="flex flex-row">
        <a href="tel:+375447107865">
          <img class="footer-icon grow-on-hover-110" src="assets/images/phone_gray.png" />
        </a>
        <a href="mailto:info@bagora.by" class="ml-1">
          <img class="footer-icon grow-on-hover-110" src="assets/images/envelope_gray.png" />
        </a>
      </div>
      <div class="space-y-1 ml-1">
        <p class="text-white text-size-1 mt-0 mb-0">+375 29 654-29-73</p>
        <p class="text-white text-size-1 mt-0 mb-0">info@bagora.by</p>
      </div>
    </div>

    <div>
      <h3 class="arsenal-bold footer-title-font bottom-margin-footer-title">УСЛУГИ</h3>
      <ul class="footer-list-space-y pl-0">
        <li>
          <a
            routerLink="/devices"
            class="footer-item-font"
          >каталог устройств</a>
        </li>
        <li>
          <a
            routerLink="/dispatching"
            class="footer-item-font"
          >диспетчеризация</a>
        </li>
        <li>
          <a
            routerLink="/calibration"
            class="footer-item-font"
          >поверка</a>
        </li>
        <li>
          <a
            routerLink="/customization"
            class="footer-item-font"
          >изготовление под заказ</a>
        </li>
      </ul>
    </div>

    <div>
      <h3 class="arsenal-bold footer-title-font bottom-margin-footer-title">ПОДРОБНЕЕ</h3>
      <ul class="footer-list-space-y pl-0">
        <li>
          <a
            routerLink="/documentation"
            class="footer-item-font"
          >документация</a>
        </li>
        <li>
          <a
            routerLink="/programs"
            class="footer-item-font"
          >ПО</a>
        </li>
        <li>
          <a
            routerLink="/clients"
            class="footer-item-font"
          >наши клиенты</a>
        </li>
        <!--<li>
          <a
            routerLink="/history"
            class="footer-item-font"
          >наша история</a>
        </li>-->
      </ul>
    </div>

    <div>
      <h3 class="arsenal-bold footer-title-font bottom-margin-footer-title">ПРЕСС-ЦЕНТР</h3>
      <ul class="footer-list-space-y pl-0">
        <li>
          <a
            routerLink="/gallery"
            class="footer-item-font"
          >галерея</a>
        </li>
        <li>
          <a
            routerLink="/instructions"
            class="footer-item-font"
          >видеоинструкции</a>
        </li>
        <li>
          <a
            routerLink="/articles"
            class="footer-item-font"
          >статьи</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
