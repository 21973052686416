import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CheckBoxState} from "@app/additional/CheckBoxState";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() index: number;
  @Input() text: string;
  @Input() value: boolean;

  @Output() itemSelection = new EventEmitter<CheckBoxState>();

  form = new FormGroup({
    check: new FormControl<boolean>(false)
  })

  ngOnInit() {
    this.form.controls.check.setValue(this.value);
  }

  ngOnChanges() {
    this.form.controls.check.setValue(this.value);
  }

  selectionChanged() {
    let active = this.form.controls.check.getRawValue();
    let checkBoxState = new CheckBoxState(this.index, this.text, active);
    this.itemSelection.emit(checkBoxState);
  }
}
