<app-header></app-header>

<p class="left-side pr-4 mb-0">
  <span class="title-bold-no-left-paddings mb-0 pb-0 block line-height-1-2">ПОВЕРКА И РЕМОНТ</span>
  <span class="title-bold-yellow-no-left-paddings mt-0 pt-0 block line-height-1-2">ЦИФРОВЫХ МАНОМЕТРОВ МЦ-1,6</span>
</p>

<div class="block-bottom-padding left-side">
  <div class="offer-container">
    <div class="grid grid-cols-1 gap-x-0 gap-y-3">
      <div class="flex flex-row items-center space-x-2 semi:space-x-3">
        <img src="assets/images/law-book.png" class="offer-icon"/>
        <span class="text-size-3 text-dark-color normal-line-height">поверка вне сферы законодательной метрологии</span>
      </div>
      <div class="flex flex-row items-center space-x-2 semi:space-x-3">
        <img src="assets/images/manometer%20calibration.png" class="offer-icon"/>
        <span class="text-size-3 text-dark-color normal-line-height">методика поверки МРБ МП.1924-2009</span>
      </div>
      <div class="flex flex-row items-center space-x-2 semi:space-x-3">
        <img src="assets/images/calendar.png" class="offer-icon"/>
        <span class="text-size-3 text-dark-color normal-line-height">до 10 рабочих дней с момента поступления в лабораторию</span>
      </div>
      <div class="flex flex-row items-center space-x-2 semi:space-x-3">
        <img src="assets/images/coins.png" class="offer-icon"/>
        <span class="text-size-3 text-dark-color normal-line-height">7.10 р за поверку одного манометра (без НДС)</span>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ИНСТРУКЦИЯ</h2>

<div class="max-w-full block-bottom-padding left-side">
  <div *ngFor="let instruction of instructions" class="mb-4">
    <button
      class="flex items-center gap-2 w-full transition-all duration-300 hover:bg-gray-100"
      (click)="toggle(instruction)">
      <span class="text-size-5 oswald">{{ instruction.name }}</span>
      <svg
        class="w-6 h-6 transition-transform duration-300"
        [ngClass]="{ 'rotate-180': openedInstruction === instruction }"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round">
        <path d="M5 9l7 7 7-7"></path>
      </svg>
    </button>

    <div #contentRef
         class="overflow-hidden transition-all duration-300 location-block"
         [ngStyle]="{
        'max-height': openedInstruction === instruction ? contentHeights[instruction.name] + 'px' : '0px'
      }">
      <div class="relative">
        <div class="absolute h-full right-3 mobile:right-5 semi-2:right-0 flex flex-col items-center justify-content-around gap-y-10 z-0 transition-all duration-300"
             [ngStyle]="{'opacity': openedInstruction === instruction ? '1' : '0'}">
          <img class="illustration-instruction" src="assets/images/paper.png"/>
          <img class="illustration-instruction" src="assets/images/goal_calibration.png"/>
          <img class="illustration-instruction" src="assets/images/package.png"/>
        </div>
        <div *ngFor="let step of instruction.steps; let i = index" class="mt-3 instruction-block z-1">
          <div class="relative flex flex-row items-center gap-x-3 mobile:gap-x-4 md:gap-x-5">
            <span class="text-blue-color oswald-medium bullet-digit">{{i + 1}}</span>
            <span class="text-dark-color text-size-3 inline-block line-height-1-2 bullet-text">{{step}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ПРИМЕР ЗАПОЛНЕННОЙ ЗАЯВКИ</h2>

<div class="bottom-page-padding left-side">
  <div class="flex flex-row gap-x-5 items-center">
    <span class="text-dark-color text-size-3 oswald">ШАБЛОН ЗАЯВКИ</span>
    <a href="assets/documents/Zayavka_poverka.docx"><button type="button" class="button-blue-hover-yellow text-size-2 py-2 px-4"
    >Скачать</button></a>
  </div>
  <img src="assets/images/request_example.jpg" class="mt-4 request-example-image"/>
</div>

<app-footer></app-footer>
