import {Component, OnInit} from '@angular/core';
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {Characteristic} from "@app/entity/Characteristic";

@Component({
  selector: 'app-detector-temperature-indicating-page',
  templateUrl: './detector-temperature-indicating-page.component.html',
  styleUrls: ['../../own-block-styles.css', './detector-temperature-indicating-page.component.css']
})
export class DetectorTemperatureIndicatingPageComponent implements OnInit {
  private documentationService: DocumentationService;
  detectorTemperature: DeviceDocumentation;

  characteristics: Characteristic[] = [
    {bigText: '9-15 В', middleText: 'постоянный ток', smallText: 'питающее напряжение'},
    {bigText: '25 мА', middleText: 'потребляемый ток', smallText: 'от внешнего источника'},
    {bigText: '-10°C - +125°С', middleText: 'диапазон измерений', smallText: ''},
    {bigText: '3 разряда', middleText: 'светодиодное табло', smallText: ''},
    {bigText: '-10°C - +50°С', middleText: 'рабочая температура', smallText: ''},
    {bigText: '0.5°С', middleText: 'погрешность в диапазоне', smallText: 'от -10°С до +85°С'},
    {bigText: '2°С', middleText: 'погрешность в диапазоне', smallText: 'от -85°С до +125°С'},
    {bigText: 'RS-485', middleText: 'интерфейс связи', smallText: 'с верхним уровнем'},
    {bigText: 'MODBUS RTU', middleText: 'протокол связи', smallText: 'с верхним уровнем'},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '110х65х45 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP40', middleText: 'степень защиты', smallText: ''},
    {bigText: '150 г', middleText: 'масса', smallText: ''}
  ];

  constructionProperties: string[] = [
    'модуль индикации в пластмассовом цилиндрическом корпусе с цифровым табло',
    'выносной сенсор температуры в запаянной медной трубке',
    'модуль индикации и трубка с сенсором соединены гибким кабелем',
    'линии защищены от неправильной полярности и перегрузки от\u00A0\u201115\u00A0В\u00A0до\u00A015\u00A0В'
  ];

  advices: string[] = [
    'при монтаже закрепите модуль индикации с помощью кронштейна в комплекте к элементам несущих конструкций',
    'поместите трубку с сенсором в зону контроля температуры',
    'для подключения к линиям питания и передачи данных используйте кабель типа UTP 2x2x0.52'
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.detectorTemperature = this.documentationService.getDetectorTemperatureIndicatingDocumentation();
  }
}
