<app-header></app-header>

<h1 class="title-bold-no-left-paddings left-side">ДИСПЕТЧЕРИЗАЦИЯ АРТЕЗИАНСКИХ СКВАЖИН И СТАНЦИЙ ОБЕЗЖЕЛЕЗИВАНИЯ</h1>

<div class="block-bottom-padding left-side">
  <a class="inline-block" href="assets/images/mockup10-solution.png">
    <img class="smallest-scheme-image" src="assets/images/mockup10-solution.png"/>
  </a>
</div>

<div class="block-bottom-padding left-side">
  <div class="flex flex-col pr-5 gap-y-8">
    <div *ngFor="let parameterSet of parameterSets" class="flex flex-col gap-y-5">
      <p class="text-size-6 text-blue-color oswald mt-0 mb-0 line-height-1-0">{{parameterSet.name}}</p>
      <div class="flex flex-col gap-y-3 pr-5">
        <div *ngFor="let parameter of parameterSet.steps; let i = index">
          <div class="relative flex flex-row items-center gap-x-2 md:gap-x-3">
            <span class="text-blue-color oswald-medium smaller-bullet-digit">{{i + 1}}</span>
            <span class="text-dark-color text-size-3 inline-block line-height-1-1 bullet-text">{{parameter}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<h2 class="title-bold-no-left-paddings left-side">ЧТО МЫ ПРЕДЛАГАЕМ</h2>

<div class="block-bottom-padding left-side">
  <app-offer-block></app-offer-block>
</div>

<h2 class="title-bold-no-left-paddings left-side">ПЕРЕЧЕНЬ АППАРАТУРЫ</h2>

<div class="bottom-page-padding left-side">
  <div class="equipment-grid grid grid-cols-1 semi-2:grid-cols-2 xl:grid-cols-3 gap-x-20 gap-y-8 mobile:gap-y-6">
    <div *ngFor="let equipment of equipments" class="flex flex-row gap-x-4 items-center">
      <div class="equipment-image-div relative">
        <svg
          class="absolute inset-0 z-0 h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <circle class="inline" cx="100" cy="100" r="98" fill="#FFFFFF"/>
        </svg>
        <img src="{{equipment.imagePath}}" class="relative my-auto h-full w-full"/>
      </div>
      <div class="flex flex-col gap-y-2">
        <span class="text-size-equipment oswald-medium text-blue-color line-height-1-1">{{equipment.name}}</span>
        <span class="text-size-equipment oswald text-cool-gray-color line-height-1-1">{{equipment.amountStr}}</span>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
