import { Component } from '@angular/core';
import {GalleryFolder} from "@app/entity/GalleryFolder";

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.css']
})
export class GalleryPageComponent {
  private initialCommonImages: string[] = [
    'assets/images/gallery/devices_5.JPG'
  ];

  galleryFolders: GalleryFolder[] = [
    {name: 'устройства', images: [
        'assets/images/gallery/devices_1.JPG',
        'assets/images/gallery/devices_2.JPG',
        'assets/images/gallery/devices_3.JPG',
        'assets/images/gallery/devices_4.JPG'
    ]}
  ];

  images: string[];

  constructor() {
    this.fillImages();
    console.log(this.images);
  }

  private fillImages() {
    let allImages = [];
    allImages.push(...this.initialCommonImages);
    let folderImages = this.galleryFolders.flatMap(folder => folder.images);
    this.clearArrayForPercent(folderImages, 50);

    allImages.push(...folderImages);
    this.images = this.shuffleArray(allImages);
  }

  private shuffleArray(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  private clearArrayForPercent(array: string[], percent: number) {
    let index = 0;
    const probability = percent / 100;
    while (index < array.length) {
      if (probability > Math.random()) {
        array.splice(index, 1);
      } else {
        index++;
      }
    }
  }
}
