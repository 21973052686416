import {Component, OnInit} from '@angular/core';
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {Characteristic} from "@app/entity/Characteristic";

@Component({
  selector: 'app-detector-temperature-page',
  templateUrl: './detector-temperature-page.component.html',
  styleUrls: ['../../own-block-styles.css', './detector-temperature-page.component.css']
})
export class DetectorTemperaturePageComponent implements OnInit {
  private documentationService: DocumentationService;
  detectorTemperature: DeviceDocumentation;

  characteristics: Characteristic[] = [
    {bigText: '5-30 В', middleText: 'постоянный ток', smallText: 'питающее напряжение'},
    {bigText: '12 мА', middleText: 'потребляемый ток', smallText: 'от внешнего источника'},
    {bigText: '-10°C - +125°С', middleText: 'диапазон измерений', smallText: ''},
    {bigText: '-10°C - +50°С', middleText: 'рабочая температура', smallText: ''},
    {bigText: '0.5°С', middleText: 'погрешность в диапазоне', smallText: 'от -10°С до +85°С'},
    {bigText: '2°С', middleText: 'погрешность в диапазоне', smallText: 'от -85°С до +125°С'},
    {bigText: 'RS-485', middleText: 'интерфейс связи', smallText: 'с верхним уровнем'},
    {bigText: 'MODBUS RTU', middleText: 'протокол связи', smallText: 'с верхним уровнем'},
    {bigText: '10 м', middleText: 'длина линии связи', smallText: ''},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '65х16х10 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP55', middleText: 'степень защиты', smallText: ''},
    {bigText: '100 г', middleText: 'масса', smallText: ''}
  ];

  constructionProperties: string[] = [
    'модуль на плате в термоусадочной трубке внутри пластмассовой трубки',
    'выносной сенсор температуры в запаянной медной трубке',
    'электронный модуль и трубка с сенсором соединены гибким кабелем'
  ];

  advices: string[] = [
    'при монтаже закрепите модуль с помощью пластмассовых клипс в комплекте или стяжек к элементам несущих конструкций',
    'поместите трубку с сенсором в зону контроля температуры',
    'в качестве линии связи используйте кабель типа UTP 2x2x0.52, где одна витая пара используется для подачи питания, а вторая - для передачи данных'
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.detectorTemperature = this.documentationService.getDetectorTemperatureDocumentation();
  }
}
