import {Component, OnInit} from '@angular/core';
import {DocumentationService} from "@app/service/documentation.service";
import {DeviceDocumentation} from "@app/entity/DeviceDocumentation";
import {Characteristic} from "@app/entity/Characteristic";

@Component({
  selector: 'app-manometer-page',
  templateUrl: './manometer-page.component.html',
  styleUrls: ['../../own-block-styles.css', './manometer-page.component.css']
})
export class ManometerPageComponent implements OnInit {
  private documentationService: DocumentationService;
  manometer: DeviceDocumentation;

  characteristics: Characteristic[] = [
    {bigText: '9-15 В', middleText: 'постоянный ток', smallText: 'питающее напряжение'},
    {bigText: '25 мА', middleText: 'потребляемый ток', smallText: 'от внешнего источника'},
    {bigText: '0-1.6 МПа', middleText: 'диапазон измерений', smallText: ''},
    {bigText: '3 разряда', middleText: 'светодиодное табло', smallText: ''},
    {bigText: '0.01 МПа', middleText: 'младший разряд', smallText: ''},
    {bigText: '1,5 %', middleText: 'предел погрешности', smallText: ''},
    {bigText: 'RS-485', middleText: 'интерфейс связи', smallText: 'с верхним уровнем'},
    {bigText: 'MODBUS RTU', middleText: 'протокол связи', smallText: 'с верхним уровнем'},
    {bigText: '3 секунды', middleText: 'установка показаний', smallText: ''},
    {bigText: '9600 бит/с', middleText: 'передача данных', smallText: ''},
    {bigText: '+5°С - +60°С', middleText: 'рабочая температура', smallText: ''},
    {bigText: '10+ лет', middleText: 'срок службы', smallText: ''},
    {bigText: 'драгметаллы', middleText: 'не содержит', smallText: ''},
    {bigText: '64х45х82 мм', middleText: 'габаритный размер', smallText: ''},
    {bigText: 'IP40', middleText: 'степень защиты', smallText: ''},
    {bigText: '110 г', middleText: 'масса', smallText: ''}
  ];

  modeTypes: string[] = [
    'автоматический с частотой 4 Гц',
    'адресный по запросу от внешнего контроллера'
  ]

  constructionProperties: string[] = [
    'выполнен в пластмассовом цилиндрическом корпусе с прозрачной передней крышкой',
    'цифровое трехразрядное табло измеряемого давления на лицевой панели',
    'линии защищены от неправильной полярности и перегрузки от\u00A0\u201130\u00A0В\u00A0до\u00A030\u00A0В'
  ];

  advices: string[] = [
    'для подключения манометра используйте кабель типа UTP 2х2х0.52 и кабель, входящий в комплект поставки',
    'для монтажа манометра используйте штуцер с резьбой М12 х 1,5 мм и гаечный ключ на 14 мм'
  ];

  constructor(documentationService: DocumentationService) {
    this.documentationService = documentationService;
  }

  ngOnInit() {
    this.manometer = this.documentationService.getManometerDocumentation();
  }
}
