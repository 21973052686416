<app-header></app-header>

<h1 class="title-bold text-center">КОНТАКТЫ</h1>
<div class="contact-container mx-auto block-bottom-padding">
  <div class="grid grid-cols-1 gap-y-12 sm:grid-cols-2 lg:grid-cols-6 text-center">
    <div class="flex flex-col items-center col-span-1 lg:col-span-2 lg:justify-self-start">
      <img src="assets/images/phone.png" alt="Phone" class="contact-item">
      <p class="text-size-4 normal-line-height mb-0">+375 44 710-78-65</p>
      <p class="text-size-2 normal-line-height margin-between-title-and-description">отдел продаж</p>
    </div>

    <div class="flex flex-col items-center col-span-1 lg:col-span-2 lg:justify-self-center">
      <img src="assets/images/phone.png" alt="Phone" class="contact-item">
      <p class="text-size-4 normal-line-height mb-0">+375 29 654-29-73</p>
      <p class="text-size-2 normal-line-height margin-between-title-and-description">главный инженер</p>
    </div>

    <div class="flex flex-col items-center col-span-1 sm:col-span-2 lg:col-span-2 lg:justify-self-end">
      <img src="assets/images/phone.png" alt="Phone" class="contact-item">
      <p class="text-size-4 normal-line-height mb-0">+375 29 653-27-47</p>
      <p class="text-size-2 normal-line-height margin-between-title-and-description">директор</p>
    </div>

    <div class="flex flex-col items-center col-span-1 sm:col-span-2 lg:col-span-3 lg:justify-self-center lg:ml-20">
      <img src="assets/images/mail.png" alt="Email" class="contact-item">
      <p class="text-size-4 normal-line-height">info@bagora.by</p>
    </div>

    <div class="flex flex-col items-center col-span-1 sm:col-span-2 lg:col-span-3 lg:justify-self-center lg:mr-14">
      <img src="assets/images/address.png" alt="Address" class="contact-item">
      <p class="text-size-4 normal-line-height">г. Минск, ул. Заславская,<br>д. 12, оф. 356а, 220004</p>
    </div>
  </div>
</div>

<h2 class="title-bold text-center">РЕКВИЗИТЫ</h2>
<div class="w-full mx-auto flex justify-center items-center bottom-page-padding">
  <div class="w-full pr-5 pl-5">
    <div class="flex flex-col">
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">Наименование</div>
        <div class="requisit-value-width text-start text-requisit">Частное производственное унитарное предприятие "Багора"</div>
      </div>
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">Юридический адрес</div>
        <div class="requisit-value-width text-start text-requisit">220004, г. Минск, ул. Заславская, 12, оф. 356а</div>
      </div>
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">Расчетный счет</div>
        <div class="requisit-value-width text-start text-requisit">BY22 AKBB 3012 0000 1541 6510 0000</div>
      </div>
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">Банк</div>
        <div class="requisit-value-width text-start text-requisit">
          ЦБУ № 510 ОАО "АСБ Беларусбанк", 220005, г. Минск, пр. Независимости, 56
        </div>
      </div>
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">БИК банка</div>
        <div class="requisit-value-width text-start text-requisit">AKBBBY2X</div>
      </div>
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">УНП</div>
        <div class="requisit-value-width text-start text-requisit">190990769</div>
      </div>
      <div class="flex mb-3">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">ОКПО</div>
        <div class="requisit-value-width text-start text-requisit">377905115000</div>
      </div>
      <div class="flex">
        <div class="requisit-key-width text-end arsenal-bold text-requisit requisit-cols-margin">Директор</div>
        <div class="requisit-value-width text-start text-requisit">Левкович Валентина Евгеньевна</div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
