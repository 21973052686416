<app-header></app-header>

<h1 class="title-bold text-center">ДОКУМЕНТАЦИЯ</h1>

<div class="px-10 flex flex-col w-full align-items-center" *ngFor="let document of commonDocuments">
  <a href="{{document.documentPath}}">
    <div class="common-document flex flex-row space-x-1 lg:space-x-1.5 cursor-pointer align-items-center relative">
      <img src="{{document.iconPath}}" class="document-icon"/>
      <span class="text-size-2 text-dark-color normal-line-height">{{document.documentName}}</span>
    </div>
  </a>
</div>

<div *ngIf="deviceDocumentations" class="device-container mx-auto mt-2 pt-2 pl-2 pr-4 bottom-page-padding">
  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-2 mobile:gap-y-4 md:gap-y-0 gap-x-5 lg:gap-x-10">
    <div *ngFor="let documentation of deviceDocumentations" class="relative py-2">
      <div class="flex flex-col items-center space-y-2">
        <div class="flex flex-col items-center space-y-3 relative device-click cursor-pointer z-1 pb-2"
          [routerLink]="'/' + documentation.device.routerLink">
          <svg
            class="grow-on-device-hover absolute top-16 mobile:top-24 md:top-16 xl:top-20 z-0"
            width="200"
            height="200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle class="hidden xl:inline" cx="100" cy="100" r="100" fill="#FFFFFF" />
            <circle class="hidden md:inline xl:hidden" cx="100" cy="100" r="90" fill="#FFFFFF" />
            <circle class="hidden mobile:inline md:hidden" cx="97.5" cy="100" r="95" fill="#FFFFFF" />
            <circle class="inline mobile:hidden" cx="97.5" cy="100" r="75" fill="#FFFFFF" />
          </svg>
          <img src="{{documentation.device.bottomAlignImagePath}}" class="device-image relative"/>
          <div class="flex flex-col">
            <h3 class="text-dark-color text-center text-size-6 oswald-medium relative normal-line-height">{{documentation.device.shortName}}</h3>
            <p class="description-size text-blue-color text-center text-size-2 oswald relative normal-line-height mb-0 px-3">{{documentation.device.longName}}</p>
          </div>
        </div>

        <button
          class="toggle-button text-blue-color group"
          (click)="toggleDocumentation(documentation.device.id)"
          [ngClass]="{ 'rotate-180': isDocumentationVisible(documentation.device.id) }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-14 h-6 transition-transform duration-300 ease-in-out group-hover:scale-110"
            viewBox="0 0 24 10"
            fill="none"
            stroke="currentColor"
            stroke-width="1">
            <path
              d="M3 2 L12 8 L21 2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>

      <div
        class="document-list transition-all overflow-hidden flex flex-col items-center space-y-1"
        [style.height]="isDocumentationVisible(documentation.device.id) ? 'auto' : '0'">
        <div
          *ngFor="let doc of documentation.documents"
          class="common-document flex flex-row space-x-1 lg:space-x-1.5 cursor-pointer align-items-center relative">
          <img src="{{doc.iconPath}}" class="document-icon"/>
          <a href="{{doc.documentPath}}">
            <span class="text-size-2 text-dark-color normal-line-height py-2.5">{{doc.documentName}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
