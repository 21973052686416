import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-arrow-title',
  templateUrl: './arrow-title.component.html',
  styleUrls: ['./arrow-title.component.css']
})
export class ArrowTitleComponent {
  @Input() title: string;
  @Input() routerLinkOnArrow: string;
}
