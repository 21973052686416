<div class="mt-0 mb-0 flex flex-row align-items-center">
  <input
    class="mt-1"
    type="checkbox"
    name="{{text}}"
    [formControl]="form.controls.check"
    [value]="false"
    (change)="selectionChanged()"
  />
  <span class="text-black text-size-1 pl-1.5 pt-1 pb-1 less-line-height">{{text}}</span>
</div>
