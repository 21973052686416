import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Moving} from "@app/additional/Moving";

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() images: string[] = [];
  @Input() autoSlideInterval: number = 4000;

  @ViewChild('carouselContainer') carouselContainer!: ElementRef;

  private slideTimer: any;
  private timeoutId: any;

  parentWidth: number;
  containerWidth: number;
  private imageRatio = 4/3;
  private fullSideImagesShown: number;
  private totalSmallImagesShown: number;
  private bigImageScale = 1.5;
  private smallImageMoving: number;
  private bigImageMoving: number;
  private smallImageWidth: number;
  private bigImageWidth: number;
  private smallImageHeight: number;
  bigImageHeight: number;
  margin: number;
  private marginPercentFromSmallImageWidth = 0.05;

  private middleIndex: number;
  private currentIndex: number;

  imagesToShow: string[];

  moveLeft = false;
  animate = true;
  isMoving = false;

  animationLength = 800;

  private movingsQueue: Moving[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.recalculateSizesAndStartCarousel();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.recalculateSizesAndStartCarousel();
  }

  private recalculateSizesAndStartCarousel() {
    const parentWidth = this.carouselContainer.nativeElement.clientWidth;
    this.calculateParametersFromParentWidth(parentWidth);
    this.imagesToShow = this.findImagesToShow();

    this.cdr.detectChanges();
    this.startAutoSlide();
  }

  ngOnDestroy(): void {
    this.stopAutoSlide();
    this.clearTimeout();
  }

  getTransformStyleForIndex(index: number): string {
    if (this.isMoving) {
      let sign = '';
      if (!this.moveLeft) {
        sign = '-';
      }
      return `translateX(${sign}${this.smallImageMoving}px)`;
    } else {
      return 'translateX(0px)';
    }
  }

  startAutoSlide() {
    this.clearTimeout();
    this.stopAutoSlide();
    this.timeoutId = setTimeout(() => {
      this.slideTimer = setInterval(() => {
        this.goToSlide();
      }, this.autoSlideInterval);
    }, 0);
  }

  stopAutoSlide() {
    if (this.slideTimer) {
      clearInterval(this.slideTimer);
      this.slideTimer = null;
    }
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  goToNextSlide() {
    this.stopAutoSlide();
    this.clearTimeout();

    this.movingsQueue.push(Moving.right);
    if (this.movingsQueue.length === 1) {
      this.onSlide();
    }
  }

  goToPreviousSlide() {
    this.stopAutoSlide();
    this.clearTimeout();

    this.movingsQueue.push(Moving.left);
    if (this.movingsQueue.length === 1) {
      this.onSlide();
    }
  }

  private goToSlide() {
    if (this.moveLeft) {
      this.movingsQueue.push(Moving.left);
    } else {
      this.movingsQueue.push(Moving.right);
    }

    this.onSlide();
  }

  private onSlide(): void {
    if (this.movingsQueue.length === 0) {
      if (!this.slideTimer) {
        this.startAutoSlide();
      }
      return;
    }
    const moving = this.movingsQueue[0];
    if (moving === Moving.left) {
      this.moveLeft = true;
      this.decrementCurrentIndex();
    } else if (moving === Moving.right) {
      this.moveLeft = false;
      this.incrementCurrentIndex();
    }

    this.animate = true;
    this.isMoving = true;

    setTimeout(() => {
      this.animate = false;
      this.replaceImages();
      this.isMoving = false;
      setTimeout(() => {
        this.animate = true;
        this.movingsQueue.shift();
        this.onSlide();
      }, 50);
    }, this.animationLength);
  }

  getImageWidthByIndex(index: number): number {
    if (this.mustImageByIndexBeBig(index)) {
      return this.bigImageWidth;
    } else {
      return this.smallImageWidth;
    }
  }

  getImageHeightByIndex(index: number): number {
    if (this.mustImageByIndexBeBig(index)) {
      return this.bigImageHeight;
    } else {
      return this.smallImageHeight;
    }
  }

  private mustImageByIndexBeBig(index: number): boolean {
    if (!this.isMoving) {
      return index === this.middleIndex;
    } else if (this.moveLeft && index === this.middleIndex - 1) {
      return true;
    } else {
      return (!this.moveLeft && index === this.middleIndex + 1);
    }
  }

  private replaceImages(): void {
    this.imagesToShow = this.findImagesToShow();
  }

  private findImagesToShow(): string[] {
    const minIndex = this.currentIndex - this.fullSideImagesShown - 2;
    const maxIndex = this.currentIndex + this.fullSideImagesShown + 2;

    const imagesToShow = [];
    for (let i = minIndex; i <= maxIndex; i++) {
      imagesToShow.push(this.findImageByIndex(i));
    }
    return imagesToShow;
  }

  private findImageByIndex(index: number): string {
    while (index < 0 || index >= this.images.length) {
      if (index < 0) {
        index = this.images.length + index;
      }
      if (index >= this.images.length) {
        index = index - this.images.length;
      }
    }
    return this.images[index];
  }

  private incrementCurrentIndex() {
    this.currentIndex++;
    if (this.currentIndex >= this.images.length) {
      this.currentIndex = 0;
    }
  }

  private decrementCurrentIndex() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.images.length - 1;
    }
  }

  private calculateParametersFromParentWidth(parentWidth: number) {
    this.parentWidth = parentWidth;
    this.fullSideImagesShown = this.calculateSideImagesShown();
    this.totalSmallImagesShown = 0.5 + this.fullSideImagesShown * 2 + 0.5;

    const marginCount = this.fullSideImagesShown * 2 + 2;
    const visibleParts = marginCount * this.marginPercentFromSmallImageWidth +
      this.totalSmallImagesShown * 1 + 1 * this.bigImageScale;
    this.smallImageWidth = parentWidth / visibleParts;
    this.margin = this.smallImageWidth * this.marginPercentFromSmallImageWidth;
    this.bigImageWidth = this.smallImageWidth * this.bigImageScale;
    this.smallImageHeight = this.smallImageWidth / this.imageRatio;
    this.bigImageHeight = this.bigImageWidth / this.imageRatio;

    this.containerWidth = this.parentWidth + 2 * this.margin + (2 + 0.5 + 0.5) * this.smallImageWidth;

    this.smallImageMoving = this.smallImageWidth + this.margin;
    this.bigImageMoving = this.smallImageWidth * 0.5 + this.margin + this.bigImageWidth * 0.5;

    this.middleIndex = 2 + this.fullSideImagesShown;
    this.currentIndex = this.middleIndex;
  }

  private calculateSideImagesShown(): number {
    if (this.parentWidth >= 1180) {
      return 2;
    } else if (this.parentWidth >= 660) {
      return 1;
    } else {
      return 0;
    }
  }
}
